import Barba from 'barba.js';
import Env from '../base/env.js';
import TweenMax from 'gsap/TweenMax.js';
import Channels from '../base/channels.js';

let MainTransitions = Barba.BaseTransition.extend({
    posScroll : 0,
    start: function()
    {
        const currentController = this.oldContainer.getAttribute('data-namespace')

        // De liste à fiche
        if(Env.enteringSingle == true){
            Promise
            .all([this.newContainerLoading, this.worksOut()])
            .then(this.workSingleIn.bind(this))
        } else if(Env.enteringNext == true){
            Promise
            .all([this.newContainerLoading, this.worksSingleNextOut()])
            .then(this.workSingleNextIn.bind(this))
        } else {
            Promise
            .all([this.newContainerLoading, this.checkIn()])
            .then(this.dispatchTransition.bind(this))
        }


    },
    none: function()
    {
        const deferred = Barba.Utils.deferred();
        this.oldContainer.style.width = this.oldContainer.clientWidth + 'px'
        deferred.resolve();
    },
    checkIn:function()
    {
        const deferred = Barba.Utils.deferred();
        const viewport  = Env.framework.toolbox.getViewport()
        const statusMainTransition = Env.framework.MainTransition.getStatus()
        const statusMainnav = Env.framework.mainnav.getStatus()

        this.oldContainer.style.width = this.oldContainer.clientWidth + 'px'

        // si menu ouvert et transition est active
        if(statusMainnav == true && statusMainTransition == 'in') {
            deferred.resolve();
        } else {
            Env.framework.MainTransition.in(
                null,
                ()=>{
                    deferred.resolve();
                }
            )
        }
        return deferred.promise;
    },
    dispatchTransition:function()
    {
        const self = this
        const currentController = this.oldContainer.getAttribute('data-namespace')
        const newController = this.newContainer.getAttribute('data-namespace')
        const statusMainTransition = Env.framework.MainTransition.getStatus()
        const statusMainnav = Env.framework.mainnav.getStatus()

        if(statusMainnav == true && statusMainTransition == 'in')
        {
            setTimeout(() => {
                Env.framework.mainnav.close(
                    ()=>{
                        Env.framework.toolbox.resetScroll()
                        this.done()
                    },
                    ()=>{
                        Channels.emit('statechange::finalize')
                        TweenLite.set(self.newContainer, { clearProps: 'all' });
                    }
                )
            }, 150);

        }
        else
        {
            setTimeout(() => {
                Env.framework.MainTransition.out(
                    ()=>{
                        Env.framework.toolbox.resetScroll()
                        this.done()
                    },
                    ()=>{
                        Channels.emit('statechange::finalize')
                        TweenLite.set(self.newContainer, { clearProps: 'all' });
                    }
                )
            }, 150);
        }
    },
    worksOut:function(){
        const deferred = Barba.Utils.deferred();
        deferred.resolve();
        return deferred.promise;
    },
    workSingleIn:function(){
        const $itemActive = this.oldContainer.querySelector('.jsItem.is--active')
        const $itemPrevious = $itemActive.previousElementSibling
        const $itemNext = $itemActive.nextElementSibling
        const $bg = $itemActive.querySelector('.bg')
        const $discover = this.oldContainer.querySelector('.btn-discover')
        const $filter = Env.$master.querySelector('.btn-filter')
        const dimensions = $bg.getBoundingClientRect()
        let finalDimensions
        if(Env.mobileCSS == true) {
            const viewport = Env.framework.toolbox.getViewport()
            finalDimensions = { width : viewport.width ,  height : viewport.height }
        } else {
            finalDimensions = { width : dimensions.width + (dimensions.width * .15) ,  height : dimensions.height + (dimensions.height * .15)}
        }
        const scaleFactor = finalDimensions.width / dimensions.width
        let tweensPrevNext = []

        let timeline = new TimelineLite({paused : true})
        timeline.add(()=>{
            $itemActive.classList.add('is--transitioning')
            if($itemPrevious != null) $itemPrevious.classList.add('is--transitioning')
            if($itemNext != null) $itemNext.classList.add('is--transitioning')
            $discover.classList.add('is--disabled')
            $filter.classList.add('is--disabled')
        })
        if($itemPrevious != null) {
            tweensPrevNext.push(TweenLite.to($itemPrevious , .6 , { x : '-7%' , opacity : 0 , ease : Cubic.easeOut }))
        }
        if($itemNext != null) {
            tweensPrevNext.push(TweenLite.to($itemNext , .6 , { x : '7%' , opacity : 0 , ease : Cubic.easeOut }))
        }
        timeline.add(tweensPrevNext)
        timeline.add([

            TweenLite.to($itemActive.querySelector('.mask') , .6 , { opacity : 0 , ease : Cubic.easeOut }),
            TweenLite.to($bg , .7 , { scale : scaleFactor , ease : Power2.easeInOut })
        ])
        timeline.add(()=>{
            $filter.classList.remove('is--disabled')
            this.oldContainer.style.visibility = 'hidden'
            this.oldContainer.style.position = 'absolute'
            this.oldContainer.style.left = '0px'
            this.oldContainer.style.top = '0px'
            this.newContainer.style.visibility = 'visible'
            Channels.emit('statechange::finalize');
            this.done()
        },'+=.8')
        setTimeout(() => {
            timeline.restart()
        }, 600);

    },
    worksSingleNextOut:function(){
        const deferred = Barba.Utils.deferred()
        this.posScroll = Env.framework.toolbox.scrollPosition().scrollY
        deferred.resolve();
        return deferred.promise;
    },
    workSingleNextIn:function(){
        Env.framework.toolbox.setScroll(0, this.posScroll)
        let timeline = new TimelineLite({paused : true})
        // Old
        const $next = this.oldContainer.querySelector('.works-single__next')
        const $bg = $next.querySelector('.bg')
        const dimensionsBg = $bg.getBoundingClientRect()
        let divTransitionOld = document.createElement('div')
        divTransitionOld.classList.add('works-single__nextTransition')
        divTransitionOld.style.backgroundColor = $next.getAttribute('data-color')
        divTransitionOld.style.zIndex = -1
        // Next
        const $cover = this.newContainer.querySelector('.works-single__cover')
        const $logo = $cover.querySelector('.logo')
        const $h2 = $cover.querySelector('.h2 .text')
        const $canvas = $cover.querySelector('canvas')
        let divTransition = document.createElement('div')
        divTransition.classList.add('cache')
        divTransition.style.backgroundColor = $cover.getAttribute('data-color')
        divTransition.style.zIndex = 2
        // Tween
        timeline.add(()=>{
            $next.classList.add('is--transitioning')
        })
        timeline.add([
            TweenLite.to($next.querySelector('.logo') , .6 , { opacity : 0 , ease : Cubic.easeIn }),
            TweenLite.to($next.querySelector('.h2 .text') , .6 , { y : '100%' , ease : Cubic.easeIn }),
            TweenLite.fromTo($bg , .7 , { clip : `rect(0px,${dimensionsBg.width}px,${dimensionsBg.height}px,0px)` } , { clip : `rect(${dimensionsBg.height}px,${dimensionsBg.width}px,${dimensionsBg.height}px,0px)` , ease : Cubic.easeInOut , delay :.1 })
        ])
        timeline.add(()=>{
            this.oldContainer.appendChild(divTransitionOld)
            divTransitionOld.style.zIndex = 10
        })
        timeline.add([
            TweenLite.fromTo(divTransitionOld , .7 , { y : '0%' } , { y : '-54%' , ease : Cubic.easeInOut })
        ])
        timeline.add(()=>{
            this.oldContainer.style.visibility = 'hidden'
            this.oldContainer.style.position = 'absolute'
            this.oldContainer.style.left = '0px'
            this.oldContainer.style.top = '0px'
            this.newContainer.style.visibility = 'visible'
            $cover.appendChild(divTransition)
        })
        timeline.add(()=>{
            this.done()
            Env.framework.toolbox.resetScroll()
        })
        timeline.add([
            TweenLite.fromTo($logo , .6 , { opacity : 0 } , { opacity : 1 , ease : Cubic.easeOut }),
            TweenLite.fromTo($h2 , .6 , { y : '80%' } ,  { y : '0%' , ease : Cubic.easeOut }),
            TweenLite.fromTo(divTransition , 1 , { x : "0%" } , { x : "-100%" , skewX : "30deg" , skewType:"simple", ease : Power2.easeInOut }),
            TweenLite.to(divTransition , .5, { skewX : "0deg" , skewType:"simple", ease : Power2.easeInOut , delay : .5 }),
        ])
        timeline.add(()=>{
            $cover.removeChild(divTransition)
            Channels.emit('statechange::finalize');
        })
        timeline.restart()
    }
})

export default MainTransitions;