import Pixi from 'pixi.js'
import Env from '../base/env'
import TweenMax from 'gsap/TweenMax'
import Channels from '../base/channels'

export default class GLCrewLetters
{
    constructor(view,data)
    {
        if(view == null) return false
        this.view = view
        this.data = data
        //  PIXI
        this.context = this.view.getBoundingClientRect()
        this.viewport = this.context
        this.renderer = new PIXI.autoDetectRenderer( 150, 150, { transparent: true })
        this.stage = new PIXI.Container()
        this.ticker = new PIXI.ticker.Ticker()
        this.letterContainer = new PIXI.Container()
        // this.displacementSprite = new PIXI.Sprite.fromImage( config.pathAssets+'/img/dmaps/2048x2048/crystalize.jpg' )
        this.displacementSprite = new PIXI.Sprite.fromImage( config.pathAssets+'/img/dmaps/lake.jpg' )
        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.displacementSprite )
        this.funcResize = this.refresh.bind(this)
        this.textStyle =  new PIXI.TextStyle({
            fill: '#fff',
            fontSize: 110,
            lineHeight:91,
            fontFamily:'Moderat Bold,sans-serif'

        });
        this.SPEED = this.data.speed || 2
        this.FILTER_STRENGHT = 50
        this.install()
        this.loadTexts()
    }

    destroy()
    {
        this.ticker.destroy()
        this.renderer.destroy()
        Channels.removeListener('window::smartresize', this.funcResize);
    }

    events()
    {
        Channels.on('window::smartresize',this.funcResize)
    }

    install()
    {
        // Add canvas to the HTML
        this.view.appendChild( this.renderer.view );
        // Add child container to the main container
        this.stage.addChild( this.letterContainer );
        // Enable Interactions
        this.stage.interactive = true;

        // Texture
        this.displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        this.stage.filters = [this.displacementFilter];

        this.displacementFilter.scale.x = 10;
        this.displacementFilter.scale.y = 10;


        // Coords
        this.displacementSprite.anchor.set(0.5);
        this.displacementSprite.x = this.renderer.width / 2;
        this.displacementSprite.y = this.renderer.height / 2;
        this.displacementSprite.scale.x = 2;
        this.displacementSprite.scale.y = 2;

        // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
        this.displacementFilter.autoFit = false;
        this.stage.addChild( this.displacementSprite );

        this.render()
    }

    loadTexts()
    {
        const letter = new PIXI.Text(this.data.text, this.textStyle);
        letter.anchor.set(0)
        letter.x = 0;
        letter.y = 0;

        // Apply
        this.letterContainer.addChild(letter)
    }

    refresh()
    {
        const viewport = Env.framework.toolbox.getViewport()
        this.renderer.view.style.width = `${viewport.width}px`
        this.renderer.view.style.height = `${viewport.height}px`
        this.viewport = viewport
    }

    render()
    {
        this.ticker.autoStart = true
        this.ticker.add((delta)=>{
            this.displacementSprite.y += 3 * (delta / this.SPEED);
            // Render our stage
            this.renderer.render( this.stage );
        });
    }
}