import Env from '../base/env.js';
import requestAnimFrame from '../vendor/raf.js';
import TweenMax from 'gsap/TweenMax.js';
import normalizeWheel from 'normalize-wheel';
import Channels from '../base/channels'

class FlowParallax
{
    constructor(view = '')
    {
        this.view = view
        this.$items = this.view.querySelectorAll('.jsParallax--item')
        this.$descReceptor = this.view.querySelector('.jsDescReceptor')
        this.$desc = this.view.querySelector('.jsDesc')
        this.parallaxObs = []
        this.raf = false
        this.scroll = 0
        this.direction = 0
        this.easing = 0.07
        this.CAN_SCROLL = true
        this.funcWheel = this.wheel.bind(this)
        this.install()
        this.events()
        this.watcher()
    }

    destroy()
    {
        this.view.removeEventListener('wheel',this.funcWheel);
        cancelAnimationFrame(this.raf)
        this.parallaxObs = []
        this.raf = false
    }

    events()
    {
        this.view.addEventListener('wheel',this.funcWheel);
    }

    wheel(e)
    {
        let scroll = normalizeWheel(e);
        if(this.CAN_SCROLL) {
            this.move(scroll);
        }
    }

    install()
    {
        for(let i = 0; i<this.$items.length;i++) {
            const $ref = this.$items[i]

            if($ref.classList.contains('is--set') == false)
            {
                var yRandom = ($ref.getAttribute('data-y') != null)?$ref.getAttribute('data-y'):Math.floor(Math.random() * 200) + 30
                var yDirection = ($ref.getAttribute('data-direction') != null)?$ref.getAttribute('data-direction'):1

                if(yDirection == 0) yDirection = -1

                this.parallaxObs.push({
                    target : $ref,
                    yRandom : yRandom,
                    yDirection : yDirection
                })
                $ref.setAttribute('data-yrandom',yRandom)
                $ref.classList.add('is--set')
            }
        }
    }

    move(scroll)
    {
        this.scroll += scroll.pixelY/10;
        this.direction = scroll.pixelY > 0 ? 1 : -1;
    }

    watcher()
    {
        var
        self = this

        this.raf = requestAnimFrame(()=>
        {
            this.watcher()
        })

        Channels.emit('watcher::raf');

        const scrollY = Env.$body.scrollTop
        const viewportHeight = Env.framework.toolbox.getViewport().height

        // Smmoth Equations : newValue to zero because we calculate the distance doing by the scroll to the final ppint, which is zero
        this.scroll += (0 - this.scroll) * this.easing

        let transform = {
            skew: {
                x:0,
                y:0.10 * this.scroll
            }
        }

        for(let i = 0; i<this.parallaxObs.length;i++) {
            const item = this.parallaxObs[i]
            const progress = item.target.getBoundingClientRect().top / viewportHeight
            const newY = (item.yRandom * item.yDirection) * progress
            const isSkew = item.target.getAttribute('data-skew')
            if(Env.tablet == false && Env.mobile == false)
            {
                if(isSkew != null)
                {
                    TweenLite.set(item.target , {  y : newY , skewY: transform.skew.y })
                }
                else{
                    TweenLite.set(item.target , {  y : newY })
                }
            }
        }
        if(this.$desc !== null) {
            this.parallax2()
        }
    }

    parallax2()
    {

        const dimensions =  this.$descReceptor.getBoundingClientRect()
        const viewport = Env.framework.toolbox.getViewport()
        const scroll = Env.framework.toolbox.scrollPosition()
        const ratio = scroll.scrollY / (viewport.height * .8)
        this.$desc.style.left = `${dimensions.x}px`
        this.$desc.style.width = `${dimensions.width}px`
        this.$desc.style.top = `${dimensions.top - 300}px`
        TweenLite.set(this.$desc , { y : ratio * 220 })
    }


}

export default FlowParallax;