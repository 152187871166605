import Barba from "barba.js";
import Env from "../base/env.js";
import Mailto from "../modules/Mailto";
import Fx from "../modules/Fx";
import GlCrewVisuals from "../gl/glCrewVisual";
import GLCrewLetters from "../gl/glCrewLetter";
import imagesLoaded from "imagesloaded";
import Channels from "../base/channels";
import Marquee3K from "../vendor/marquee3000.js";
import "babel-polyfill";

class Agency {
  constructor(view = "") {
    this.view = view;
    this.classView = "currentView--agency";
    this.$triggersFilter = this.view.querySelectorAll(".jsMemo--works");
    this.imgsToPreload = [];
    this.getImgsToPreload();
    this.$letters = [];
    this.getLettersCover();
    this.titlesLoop();
    new Mailto(this.view);
    this.funcLaunch = this.intro.bind(this);
    if (Env.framework.UI.launcher.LAUNCHED == false) this.channels();
    this.setup();
  }

  channels() {
    Channels.on("launcher::exit", this.funcLaunch);
  }

  destroy() {
    Channels.removeListener("launcher::exit", this.funcLaunch);
    this.fx.destroy();
    this.glCrewVisual.destroy();
    this.glCrewLettersC.destroy();
    this.glCrewLettersE.destroy();
    this.glCrewLettersR.destroy();
    this.glCrewLettersW.destroy();
  }

  finalize() {
    // if(Env.mobileCSS == false) {
    //     this.$letters[0].classList.add('jsParallax--letter')
    //     this.$letters[0].setAttribute('data-y',70)
    //     this.$letters[1].classList.add('jsParallax--letter')
    //     this.$letters[1].setAttribute('data-y',200)
    //     this.$letters[2].classList.add('jsParallax--letter')
    //     this.$letters[2].setAttribute('data-y',120)
    //     this.$letters[3].classList.add('jsParallax--letter')
    //     this.$letters[3].setAttribute('data-y',100)
    //     this.$letters[4].classList.add('jsParallax--letter')
    //     this.$letters[4].setAttribute('data-y',80)
    //     this.$letters[5].classList.add('jsParallax--letter')
    //     this.$letters[5].setAttribute('data-y',65)
    // }

    this.fx = new Fx(this.view);
    setTimeout(() => {
      if (Env.isVisiting === true) {
        this.introFn = this.intro();
      }
    }, 350);
  }

  titlesLoop() {
    for (let title of this.view.querySelectorAll(".js-titleloop")) {
      Marquee3K.init({
        selector: "js-titleloop",
      });
    }
  }

  getImgsToPreload() {
    // this.imgsToPreload.push(this.view.querySelector('.covertop .jsPreload--img'))
    for (const $img of this.view.querySelectorAll(
      ".jsCrewVisual--view .jsPreload--img"
    )) {
      this.imgsToPreload.push($img);
    }
  }

  getLettersCover() {
    const $lettersTop = this.view.querySelectorAll(
      ".covertop .letters-top div"
    );
    const $lettersBack = this.view.querySelectorAll(
      ".covertop .letters-back div"
    );

    for (let i = 0; i < $lettersTop.length; i++) {
      this.$letters.push($lettersTop[i]);
    }
    for (let j = 0; j < $lettersBack.length; j++) {
      this.$letters.push($lettersBack[j]);
    }
  }

  intro() {
    const $loop = this.view.querySelector(".loop");
    const $texts = this.view.querySelectorAll(".jsFxReveal");

    let timeline = new TimelineLite();
    timeline.add([
      TweenMax.fromTo(
        $loop,
        1.1,
        { autoAlpha: 0, y: 25 },
        { autoAlpha: 1, y: 0, ease: Power4.easeOut }
      ),
      TweenMax.staggerFromTo(
        $texts,
        1.1,
        { autoAlpha: 0, y: 50 },
        { autoAlpha: 1, y: 0, ease: Power4.easeOut },
        0.05
      ),
    ]);
  }

  lettersToParallax() {
    const $lettersTop = this.view.querySelectorAll(
      ".jsCrewLetterTop--view canvas"
    );
    const $lettersBack = this.view.querySelectorAll(
      ".jsCrewLetterBack--view canvas"
    );

    $lettersTop[0].classList.add("jsParallax--item");
    $lettersTop[0].setAttribute("data-y", -70);
    $lettersTop[1].classList.add("jsParallax--item");
    $lettersTop[1].setAttribute("data-y", -20);

    $lettersBack[0].classList.add("jsParallax--item");
    $lettersBack[0].setAttribute("data-y", -50);
    $lettersBack[1].classList.add("jsParallax--item");
    $lettersBack[1].setAttribute("data-y", -130);
  }

  setup() {
    this.imgLoaded = imagesLoaded(this.imgsToPreload, () => {
      setTimeout(() => {
        // Crew
        this.glCrewVisual = new GlCrewVisuals(
          this.view.querySelector(".jsCrewVisual--view")
        );
        this.glCrewLettersC = new GLCrewLetters(
          this.view.querySelector(".jsCrewLetterTop--view"),
          {
            text: "C",
            speed: 0.8,
          }
        );
        this.glCrewLettersE = new GLCrewLetters(
          this.view.querySelector(".jsCrewLetterTop--view"),
          {
            text: "E",
            speed: 1.8,
          }
        );
        this.glCrewLettersR = new GLCrewLetters(
          this.view.querySelector(".jsCrewLetterBack--view"),
          {
            text: "R",
            speed: 1.5,
          }
        );
        this.glCrewLettersW = new GLCrewLetters(
          this.view.querySelector(".jsCrewLetterBack--view"),
          {
            text: "W",
            speed: 1,
          }
        );
        this.lettersToParallax();
      }, 10);
    });
  }
}

const page = Barba.BaseView.extend({
  namespace: "agency",
  node: undefined,
  onEnter: function () {
    this.node = new Agency(this.container);
    Env.$html.classList.add(this.node.classView);
  },
  onEnterCompleted: function () {
    this.node.finalize();
  },
  onLeave: function () {
    this.node.destroy();
  },
  onLeaveCompleted: function () {
    if (this.container.getAttribute("data-namespace") != this.namespace) {
      Env.$html.classList.remove(this.node.classView);
    }
  },
});

export default page;
