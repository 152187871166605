import Env from "../base/env.js";
import Channels from "../base/channels.js";
import MainnavTouch from "../modules/mainnav-touch.js";
import Barba from "barba.js";
import GlMenu from "../gl/glMenu";
import Launcher from "../modules/Launcher";
import UISwitcher from "../modules/UIswitcher";
import "scroll-restoration-polyfill";
import "babel-polyfill";

class UI {
  constructor() {
    history.scrollRestoration = "manual";
    this.resetEnv();
    Env.framework.mainnav = new MainnavTouch();
    if (Env.desktop === true) {
      this.glMenu = this.glMenu();
    }
    this.launcher = new Launcher();
    this.uiSwitch = new UISwitcher();
    this.updateMainnavActive({
      url: window.location.href,
      namespace: Env.framework.toolbox.getController(),
    });
    this.events();
    // Env.$master.querySelectorAll('.jsMainnav--trigger')[0].click()
  }

  events() {
    document.querySelector(".jsCloseContact").addEventListener("click", () => {
      Env.$html.classList.add("contact-ui-hidden");
    });
  }

  glMenu() {
    var bg = new BG({ cvs: document.querySelector(".glsurface") });
    bg.init();
    bg.loaded = function () {
      bg.stop();
    };
    return bg;
  }

  launchAfter() {
    const $item = Env.$master.querySelector(
      ".barba-container .works-single__next"
    );
    if ($item != null && Env.enteringNext == false) {
      const getUrl = $item
        .querySelector(".jsScrollNext--link")
        .getAttribute("data-href");
      Env.isScrollingNext = true;
      Env.enteringNext = true;
      Env.enteringNext = true;
      Env.enteringSingle = false;
      Barba.Pjax.goTo(getUrl);
    }
  }

  resetEnv() {
    Channels.on("statechange::before", function () {});
    Channels.on("statechange::ready", (current, old, container) => {
      if (current.namespace != "contact") {
        Env.$html.classList.remove("skin--isDark");
      }
      this.updateMainnavActive(current);
    });
    // Channels.on('statechange::ready',(current,old,container)=>
    // {
    //     // Env.$html.classList.remove('skin--isWhite')
    //     // Env.$html.classList.remove('skin--isDark')
    //     // Env.$mainNav.classList.remove('is--active');
    //     // Env.$html.classList.remove('mainnav--isOpened')
    // })
    Channels.on("statechange::finalize", function () {
      Env.enteringSingle = false;
      Env.enteringNext = false;
      Env.isScrollingNext = false;
    });
  }

  updateMainnavActive(_context) {
    if (window.location.hash.length > 0) {
      _context.url = window.location.href.split("#")[0];
    }
    for (const e of Env.$mainNav.querySelectorAll(".primary-nav__menu li a")) {
      const href = e.getAttribute("href");
      if (href == _context.url) {
        e.parentElement.classList.add("is--active");
      } else {
        e.parentElement.classList.remove("is--active");
      }
    }
  }
}

export default UI;
