'use strict';

let env = {
    $window: window,
    $html: document.getElementsByTagName("html")[0],
    $mainContent: document.getElementById('barba-wrapper'),
    $body: document.body,
    $master: document.getElementById('master'),
    $mainNav: document.getElementById('jsView--mainnav'),
    $mainTransition: document.getElementById('jsView--transition'),
    $mainLoader: document.getElementById('jsLauncher--view'),
    framework: {},
    tablet: false,
    mobile: false,
    mobileCSS: false,
    phone: false,
    desktop: false,
    ios: false,
    ie11: false,
    ie10: false,
    edge:false,
    orientation: false,
    isVisiting: false,
    isOldie: false,
    isTouch:'ontouchstart' in window,
    events: { wheelWorks : 'mousewheel.works' },
    isScrollingNext : false,
    enteringSingle : false,
    enteringNext : false,
    worksSlideshowMemo : false
}

export default env;