import Barba from "barba.js";
import Env from "../base/env.js";

import Channels from "../base/channels";

class Merci {
  constructor(view = "") {
    this.view = view;
    this.classView = "currentView--merci";
  }

  channels() {
    // Channels.on("launcher::exit", this.funcLaunch);
  }

  destroy() {
    // Channels.removeListener("launcher::exit", this.funcLaunch);
  }

  intro() {}
}

const page = Barba.BaseView.extend({
  namespace: "merci",
  node: undefined,
  onEnter: function () {
    this.node = new Merci(this.container);
    Env.$html.classList.add(this.node.classView);
  },
  onEnterCompleted: function () {
    Env.$html.classList.add("skin--isDark");
  },
  onLeave: function () {
    this.node.destroy();
  },
  onLeaveCompleted: function () {
    if (this.container.getAttribute("data-namespace") != this.namespace) {
      Env.$html.classList.remove(this.node.classView);
    }
  },
});

export default page;
