import Env from '../base/env'
import TweenMax from 'gsap/TweenMax'

export default class MainTransition
{
    constructor()
    {
        this.view = Env.$mainTransition
        this.settings = { easing : Power2.easeInOut , duration : 1 }
        this.direction = false
        this.status = false
        this.setDirection(-1)
    }

    getStatus()
    {
        return this.status
    }



    in(funcStart='',funcComplete='')
    {
        let timeline = new TimelineLite({
            onStart:()=>{
                this.status = 'animating'
                if(typeof funcStart == 'function' || funcStart != null) funcStart()
            },
            onComplete:()=>{
                this.status = 'in'
                if(typeof funcComplete == 'function' || funcComplete != null) funcComplete()
            }
        })
        const startFrom = (this.direction > 0)?'-100%':'100%'
        const skewValue = (Env.phone == true)?'20deg':'30deg'

        timeline.add([
            TweenMax.fromTo(this.view , this.settings.duration,
                {
                    x : startFrom
                },
                {
                    x: "0%",
                    skewX: skewValue,
                    ease: this.settings.easing,
                    delay: 0,
                    skewType:"simple"
                }
            ),
            TweenMax.to(this.view , this.settings.duration/2, {
                skewX: "0deg",
                ease: this.settings.easing,
                skewType:"simple",
                delay: this.settings.duration/2
            })
        ])
    }

    out(funcStart='',funcComplete='')
    {
        let timeline = new TimelineLite({
            onStart:()=>{
                this.status = 'animating'
                if(typeof funcStart == 'function' || funcStart != null) funcStart()
            },
            onComplete:()=>{
                this.status = 'out'
                if(typeof funcComplete == 'function' || funcComplete != null) funcComplete()
            }
        })
        const goTo = (this.direction > 0)?'100%':'-100%'

        timeline.add([
            TweenMax.fromTo(this.view , this.settings.duration,
                {
                    x : '0'
                },
                {
                    x: goTo,
                    skewX: "30deg",
                    ease: this.settings.easing,
                    delay: 0,
                    skewType:"simple"
                }
            ),
            TweenMax.to(this.view , this.settings.duration/2, {
                skewX: "0deg",
                ease: this.settings.easing,
                skewType:"simple",
                delay: this.settings.duration/2
            })
        ])
    }

    setDirection(value)
    {
        this.direction = value
    }
}