import Env from '../base/env.js'
import Waypoint from '../vendor/waypoints.js'
import Channels from '../base/channels'
import "babel-polyfill"

class FlowWaypoints
{
    constructor(view)
    {
        if(typeof view == 'undefined') return;
        this.view = view;
        this.instances = []
        this.funcRefresh = this.refresh.bind(this)
        this.appear()
        this.whiteOn()
        this.darkOn()
        this.whiteOff()
        this.darkOff()
        // if(Env.desktop == true) {
        //     this.scrollNext()
        // }
        this.events()
    }

    appear()
    {
        const $items = this.view.querySelectorAll('.jsWaypoint--appearReveal')
        for(let i = 0; i<$items.length;i++)
        {
            const $ref = $items[i]
            const offset = (typeof $ref.getAttribute('data-offset') != "undefined")?$ref.getAttribute('data-offset'):"0%"

            const instance = new Waypoint({
                element: $ref,
                handler: (_direction)=> {
                    $ref.classList.add('is--shown')
                },
                offset: offset
            });
            this.instances.push(instance);
        }
    }

    destroy()
    {
        Channels.removeListener('window::smartresize', this.funcRefresh);
        for ( const $instance of this.instances )
        {
            $instance.destroy();
        }
        this.instances = []
    }

    darkOff()
    {
        for ( const e of this.view.querySelectorAll('.jsWaypoint--darkSkinOff') )
        {
            const $ref = e
            const offset = (typeof $ref.getAttribute('data-offset') != "undefined")?$ref.getAttribute('data-offset'):"0%"

            const instance = new Waypoint({
                element: $ref,
                handler: function(_direction) {
                    if(_direction == 'down')
                    {
                        Env.$html.classList.remove('skin--isDark')
                    }
                    else
                    {
                        Env.$html.classList.add('skin--isDark')
                    }

                },
                offset: offset
            });
            this.instances.push(instance);
        }
    }

    darkOn()
    {
        for ( const e of this.view.querySelectorAll('.jsWaypoint--darkSkinOn') )
        {
            const $ref = e
            const offset = (typeof $ref.getAttribute('data-offset') != "undefined")?$ref.getAttribute('data-offset'):"0%"

            const instance = new Waypoint({
                element: $ref,
                handler: function(_direction) {
                    if(_direction == 'down')
                    {
                        Env.$html.classList.add('skin--isDark')
                    }
                    else
                    {
                        Env.$html.classList.remove('skin--isDark')
                    }

                },
                offset: offset
            });
            this.instances.push(instance);
        }
    }

    events()
    {
        Channels.on('window::smartresize',this.funcRefresh)
    }

    refresh()
    {
        for(const instance of this.instances){
            instance.context.refresh()
        }
    }

    scrollNext()
    {
        for ( const e of this.view.querySelectorAll('.jsScrollNext--view'))
        {
            const $ref = e

            Env.framework.UI.instanceExit = new Waypoint({
                element: $ref,
                handler: (_direction)=> {
                    if(_direction == 'down')
                    {
                        $ref.classList.add('is--waiting')
                        Env.framework.UI.instanceExitTimer = setTimeout(function() {
                            if(Env.enteringNext == false) {
                                Env.framework.UI.launchAfter()
                            }
                        }, 1000);
                    }
                    else
                    {
                        $ref.classList.remove('is--waiting')
                        clearTimeout(Env.framework.UI.instanceExitTimer)
                    }
                },
                offset: '53.61%'
            });
        }
    }

    whiteOff()
    {
        for ( const e of this.view.querySelectorAll('.jsWaypoint--whiteSkinOff') )
        {
            const $ref = e
            const offset = (typeof $ref.getAttribute('data-offset') != "undefined")?$ref.getAttribute('data-offset'):"0%"

            const instance = new Waypoint({
                element: $ref,
                handler: function(_direction) {
                    if(_direction == 'down')
                    {
                        Env.$html.classList.remove('skin--isWhite')
                    }
                    else
                    {
                        Env.$html.classList.add('skin--isWhite')
                    }

                },
                offset: offset
            });
            this.instances.push(instance);
        }
    }

    whiteOn()
    {
        for ( const e of this.view.querySelectorAll('.jsWaypoint--whiteSkinOn') )
        {
            const $ref = e
            const offset = (typeof $ref.getAttribute('data-offset') != "undefined")?$ref.getAttribute('data-offset'):"0%"

            const instance = new Waypoint({
                element: $ref,
                handler: function(_direction) {
                    if(_direction == 'down')
                    {
                        Env.$html.classList.add('skin--isWhite')
                    }
                    else
                    {
                        Env.$html.classList.remove('skin--isWhite')
                    }

                },
                offset: offset
            });
            this.instances.push(instance);
        }
    }
}

export default FlowWaypoints;