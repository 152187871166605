import Barba from "barba.js";
import Env from "../base/env.js";
import Channels from "../base/channels";
import HomeSlideshow from "../modules/HomeSlideshowV2";
import SplitText from "../vendor/gsap-bonus/SplitText";

class Home {
  constructor(view = "") {
    this.view = view;
    this.classView = "currentView--home";
    this.$slideshows = this.view.querySelectorAll(".jsHomeSlideshowV2--view");
    // Objects
    this.slideshowsInstances = [];
    if (this.$slideshows.length) {
      for (let i = 0; i < this.$slideshows.length; i++) {
        const $slideshow = this.$slideshows[i];
        const instance = new HomeSlideshow($slideshow);
        instance.setup();
        instance.init();
        this.slideshowsInstances.push(instance);
      }
    }

    this.funcLaunch = this.intro.bind(this);
    if (Env.framework.UI.launcher.LAUNCHED == false) this.channels();
  }

  channels() {
    Channels.on("launcher::exit", this.funcLaunch);
  }

  destroy() {
    Channels.removeListener("launcher::exit", this.funcLaunch);
    if (this.$slideshows.length) {
      for (let i = 0; i < this.slideshowsInstances.length; i++) {
        const instance = this.slideshowsInstances[i];
        instance.destroy();
      }
    }
  }

  finalize() {
    this.textSplit();
    this.loadVideos();
    setTimeout(() => {
      if (Env.isVisiting === true) {
        this.introFn = this.intro();
      }
    }, 350);
  }

  intro() {
    this.slideshowsInstances[0].intro();
  }

  loadVideos() {
    this.$videos = this.view.querySelectorAll(".jsVideoLoop");
    for (let i = 0; i < this.$videos.length; i++) {
      const $video = this.$videos[i];
      if ($video != null) {
        let urlVideo;
        if (
          Env.mobileCSS == true &&
          $video.getAttribute("data-videomobile") != null &&
          $video.getAttribute("data-videomobile").length > 0
        ) {
          urlVideo = $video.getAttribute("data-videomobile");
          $video.classList.add("mobile--rendering");
        } else {
          urlVideo = $video.getAttribute("data-video");
        }
        const source = document.createElement("source");
        source.src = urlVideo;
        source.type = "video/mp4";
        $video.appendChild(source);
        $video.classList.add("is--ready");
      }
    }
  }

  textSplit() {
    const $titles = this.view.querySelectorAll(
      ".homev2-slideshow__itemManifesto .content strong"
    );

    if ($titles.length > 0) {
      for (let i = 0; i < $titles.length; i++) {
        const $title = $titles[i];
        const split = new SplitText($title, {
          type: "words",
          wordsClass: "line",
        });
      }
    }
  }
}

const page = Barba.BaseView.extend({
  namespace: "home",
  node: undefined,
  onEnter: function () {
    this.node = new Home(this.container);
    Env.$html.classList.add(this.node.classView);
  },
  onEnterCompleted: function () {
    this.node.finalize();
  },
  onLeave: function () {
    this.node.destroy();
  },
  onLeaveCompleted: function () {
    if (this.container.getAttribute("data-namespace") != this.namespace) {
      Env.$html.classList.remove(this.node.classView);
    }
  },
});

export default page;
