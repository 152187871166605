import Env from '../base/env'
import Channels from '../base/channels'
import Draggable from 'gsap/Draggable'
import ThrowPropsPlugin from '../vendor/gsap-bonus/ThrowPropsPlugin';
import Hammer from 'hammerjs'
import 'babel-polyfill'

class SSHorizontal
{
    constructor(view='',settings = { start : 0})
    {
        this.view = view
        this.settings = settings
        this.$context = this.view.querySelector('.jsContext')
        this.$collection = this.view.querySelector('.jsCollection')
        this.$items = this.view.querySelectorAll('.jsItem')
        this.$previous = this.view.querySelector('.jsPrevious')
        this.$next = this.view.querySelector('.jsNext')
        this.$totalEl = this.view.querySelector('.jsTotal')
        this.$current = this.view.querySelector('.jsCurrent')
        this.$anchors = this.view.querySelectorAll('.jsAnchor')
        this.$total = this.$items.length
        this.$cursor = this.view.querySelector('.jsCursor')
        this.marginRight = 160
        if(Env.mobileCSS === true) {
            this.marginRight = 32
        }
        this.currentIndex = this.settings.start
        this.snapPoints = [0]
        this.hammerView = new Hammer(this.view)
        this.hammerView.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
        this.funcReset = this.reset.bind(this)

        // Vars Cursor
        this.currentPosition = { x : -500 , y : -500 }
        this.newPosition = { x : 0 , y : 0 }
        this.inertia = 4

        // Fns
        this.events()
        this.install()
    }

    destroy()
    {
        Channels.removeListener('window::smartresize', this.funcReset);
    }

    drag()
    {
        this.draggable = Draggable.create(
            this.$collection,
            {
                cursor: 'none',
                type:"x",
                edgeResistance:.9,
                dragResistance : .2,
                force3D : false,
                lockAxis : true,
                throwProps:true,
                maxDuration:1.2,
                throwResistance: 3000,
                dragClickables : true,
                snap: { x: this.snapPoints },
                bounds : {minX : 0 , maxX : this.snapPoints[this.$total - 1] },
            }
        );
    }

    events()
    {

        for(let i = 0; i<this.$items.length; i++){
            const item = this.$items[i]
            item.addEventListener('click',()=>
            {
                this.move(i)
            },false)
        }

        if(this.$previous !== null) {
            this.$previous.addEventListener('click',()=>{
                this.currentIndex--;
                if(this.currentIndex < 0) this.currentIndex = 0
                this.move(this.currentIndex)
            },false)
            this.$previous.addEventListener('mouseenter',()=>{
                this.$cursor.classList.remove('for--next')
                this.$cursor.classList.add('for--previous')
            })
        }

        if(this.$next !== null) {
            this.$next.addEventListener('click',()=>{
                this.currentIndex++;
                if(this.currentIndex >= this.$total) this.currentIndex = this.$total - 1
                this.move(this.currentIndex)
            },false)
            this.$next.addEventListener('mouseenter',()=>{
                this.$cursor.classList.add('for--next')
                this.$cursor.classList.remove('for--previous')
            })
        }

        this.hammerView.on('swipeleft' , (ev)=>
        {
            if(this.$next !== null) {
                this.$next.click()
            }
        })

        this.hammerView.on('swiperight' , (ev)=>
        {
            if(this.$previous !== null) {
                this.$previous.click()
            }
        })

        this.view.addEventListener('mousemove',(e)=>{
            this.currentPosition.x = e.clientX
            this.currentPosition.y = e.clientY
        })
        this.view.addEventListener('mouseenter',()=>{
            if(this.$cursor.classList.contains('is--active') === false) {
                this.$cursor.classList.add('is--active')
            }
            if(Env.$body.classList.contains('cursor-disabled') === false) {
                Env.$body.classList.add('cursor-disabled')
            }

        })
        this.view.addEventListener('mouseleave',()=>{
            if(this.$cursor.classList.contains('is--active') === true) {
                this.$cursor.classList.remove('is--active')
            }
            if(Env.$body.classList.contains('cursor-disabled') === true) {
                Env.$body.classList.remove('cursor-disabled')
            }
        })

        Channels.on('window::smartresize',this.funcReset)
    }

    install()
    {
        let dimensions = this.$context.getBoundingClientRect()
        if(Env.mobileCSS === false) {
            dimensions.width = dimensions.width / 1.5
        }

        // const memoDimensions = dimensions

        if(dimensions.width >= 1800) {
            dimensions.width = 1800
        }
        let newPoint = 0
        this.$collection.removeAttribute('style');

        for(let i = 0; i<this.$items.length; i++){
            const item = this.$items[i]
            // const $bg = item.querySelector('.bg')
            // const $shadow = item.querySelector('.shadow')
            // const dimensionsBg = $bg.getBoundingClientRect()
            let contextHeight = dimensions.height
            if(Env.mobileCSS === true) {

                contextHeight = dimensions.height * .6

            } else {
                contextHeight = dimensions.height * .7
            }

            const $visual = item.querySelector('.visual')
            const path = $visual.getAttribute('src')
            const dimensionsVisual = { width : parseInt($visual.getAttribute('data-width')) , height : parseInt($visual.getAttribute('data-height')) }
            const widthVisual = Math.round( (dimensionsVisual.width * contextHeight) / dimensionsVisual.height )
            let itemWidth = widthVisual
            // const shadowWidth = (dimensionsVisual.width * dimensionsBg.height) / dimensionsVisual.height
            let cacheImage = new Image()

            // Shadow
            // $shadow.style.width = `${shadowWidth}px`
            // $shadow.style.height = `${dimensionsBg.height}px`

            cacheImage.onload = ()=>
            {
                item.classList.add('is--loaded')
            }
            cacheImage.src = path

            $visual.style.height = contextHeight + 'px'
            $visual.style.width = widthVisual + 'px'
            item.removeAttribute('style');

            // reset width/height if width superior to the viewport
            if(itemWidth > dimensions.width)
            {
                itemWidth = dimensions.width - 10
                const newHeight = Math.round( (itemWidth * dimensionsVisual.height) / dimensionsVisual.width )
                $visual.style.width = itemWidth + 'px'
                $visual.style.height = newHeight + 'px'

            }
            item.style.width = dimensions.width+'px'
        }

        // set snapping points
        for(let i = 0; i<this.$items.length - 1; i++){
            const item = this.$items[i]
            const $nextItem = this.$items[i+1]
            const itemWidth = dimensions.width
            if(typeof $nextItem != 'undefined')
            {
                const itemNextWidth = dimensions.width
                newPoint += ((itemWidth / 2) + this.marginRight) + (itemNextWidth / 2)
            }
            else
            {
                newPoint += (itemWidth / 2)
            }
            this.snapPoints.push(newPoint * -1)
        }
        // this.$collection.style.left = ((this.view.getBoundingClientRect().width - dimensions.width) / 2) + 'px'
        // this.$collection.style.left = 0
        if(this.$totalEl != null)
        {
            this.$totalEl.innerHTML = (this.$total.toString().length == 1)?'0'+this.$total:this.$total
        }
        this.drag()
        this.move(this.currentIndex)
    }

    move(_index)
    {
        if(_index != this.currentIndex)
        {
            this.currentIndex = _index
        }
        const indexToDisplay = this.currentIndex + 1
        TweenLite.to(this.$collection , .8 , { x : this.snapPoints[this.currentIndex] , ease: Power4.easeOut })
        if(this.$current != null)
        {
            this.$current.innerHTML = (indexToDisplay.toString().length == 1)?'0'+indexToDisplay:indexToDisplay
        }
        if(this.$items.length > 0)
        {
            const $itemActive = this.$items[this.currentIndex]
            for(let i = 0; i<this.$items.length; i++){
                const item = this.$items[i]
                item.classList.remove('is--active')
            }
            $itemActive.classList.add('is--active')
        }
    }

    reset()
    {
        this.$collection.removeAttribute('style');
        TweenLite.set(this.$items , { clearProps:"all" })
        this.currentIndex = 0
        this.snapPoints = [0]
        this.install()
    }

    onWatch()
    {
        this.newPosition.x += (this.currentPosition.x - this.newPosition.x) / this.inertia
        this.newPosition.y += (this.currentPosition.y - this.newPosition.y) / this.inertia
        TweenLite.set(this.$cursor , { x : this.newPosition.x , y : this.newPosition.y })
    }

}

export default SSHorizontal;