import Env from '../base/env.js'
import Channels from '../base/channels.js'
import "babel-polyfill"

class MainnavTouch
{
    constructor()
    {
        // Dom
        this.view = Env.$mainNav
        this.$triggers = Env.$body.querySelectorAll('.jsMainnav--trigger')
        this.$hoversGL = this.view.querySelectorAll('.jsHover--shape')
        // Flags
        this.IS_ANIMATING = false
        // Fns
        this.events()
    }
    events()
    {
        var
        self = this

        for ( const e of this.$triggers ) {
            e.addEventListener('click',()=>{
                if(this.IS_ANIMATING == false)
                {
                    Env.framework.MainTransition.setDirection(-1)
                    this.toggle()
                }
                return false;
            },false)
        }

        for(let i = 0; i < this.$hoversGL.length; i++){
            const $trigger = this.$hoversGL[i]
            $trigger.addEventListener('mouseenter',()=>{
                this.showSprite(i)
            })
        }

        Channels.on('mainnav::close',()=>
        {
            this.close()
        })
    }

    getStatus()
    {
        return this.view.classList.contains('is--active')
    }

    close(funcStart=null,funcComplete=null)
    {
        Env.framework.MainTransition.out(
            ()=>{
                this.IS_ANIMATING = true
                this.view.classList.remove('is--active');
                Env.$html.classList.remove('mainnav--isOpened')
                Env.$html.classList.add('mainnav--isLeaving')
                if(typeof funcStart == 'function' || funcStart != null) funcStart()
                if(typeof Env.framework.UI.glMenu.renderer !== "undefined") {
                    TweenLite.to(Env.framework.UI.glMenu.renderer.canvas , .4 , { scaleX : 0 , ease : Power4.easeIn ,onComplete : ()=>{
                            Env.framework.UI.glMenu.stop()
                        }
                    })
                }
            },
            ()=>{
                this.IS_ANIMATING = false
                Env.$html.classList.remove('mainnav--isLeaving')
                if(typeof funcComplete == 'function' || funcComplete != null) funcComplete()
            }
        )
    }

    showSprite(index)
   {
        Env.framework.UI.glMenu.switch(index + 1)
   }

    toggle(_status)
    {
        if (typeof _status === 'undefined')
            _status = this.view.classList.contains('is--active')

        if(!!_status)
        {
            this.close()
        }
        else
        {
            Env.framework.MainTransition.in(
                ()=>{
                    if(typeof Env.framework.UI.glMenu.renderer !== "undefined") {
                            TweenLite.to(Env.framework.UI.glMenu.renderer.canvas , .7 , { scaleX : 1 , ease : Power3.easeOut , delay : .85 , onStart : ()=>{
                                Env.framework.UI.glMenu.resume()
                            }
                        })
                    }

                    this.IS_ANIMATING = true
                    const timeout = (Env.framework.MainTransition.settings.duration / 2) * 1000
                    setTimeout(() => {
                        this.view.classList.add('is--active');
                        Env.$html.classList.add('mainnav--isOpened')
                    }, timeout);
                },
                ()=>{
                    this.IS_ANIMATING = false
                }
            )
        }
    };
}

export default MainnavTouch;