import Barba from 'barba.js'
import Env from '../base/env.js'
import Channels from '../base/channels'
import FlowWaypoints from '../modules/FlowWaypoints'
import {TweenLite, Power4} from 'gsap'
import SplitText from '../vendor/gsap-bonus/SplitText'


class Works
{
    constructor(view='')
    {
        this.view = view
        this.classView = 'currentView--workslist'
        // Fns
        this.funcLaunch = this.intro.bind(this)
        if(Env.framework.UI.launcher.LAUNCHED == false) this.channels()
    }

    adjustPrez()
    {
        const $titles = this.view.querySelectorAll('.workslist-item .title')
        if($titles.length > 0) {
            for(let i = 0; i<$titles.length; i++) {
                const $title = $titles[i]
                const color = $title.getAttribute('data-color')
                const split = new SplitText($title , {type:"lines", linesClass:"mask"})
                let innerLine = [];
                for(let j = 0; j < split.lines.length;j++) {
                    const splitLine = split.lines[j]
                    innerLine.push(new SplitText(splitLine , {type:"lines" , linesClass:"text"}))
                }

                for(let w = 0; w < innerLine.length ; w++) {
                    const colorBand = document.createElement('div')
                    colorBand.style.backgroundColor = color
                    colorBand.classList.add('colorband')
                    innerLine[w].lines[0].appendChild(colorBand)
                }
            }
        }
    }

    channels()
    {
        Channels.on('launcher::exit',this.funcLaunch)
    }

    destroy()
    {
        this.waypoints.destroy()
        if(typeof this.slideshow != "undefined") {
            Channels.removeListener('launcher::exit', this.funcLaunch);
        }
    }

    finalize()
    {
        setTimeout(() => {
            if(Env.isVisiting === true) {
                this.introFn = this.intro()
            }
            this.waypoints = new FlowWaypoints(this.view)
            this.adjustPrez()
        }, 200);
    }

    intro()
    {
        const $element = this.view.querySelector('.jsIntro')
        const $fill = $element.querySelector('.fill')
        const $bg = $element.querySelector('.bg')
        const $title = $element.querySelector('.title')
        const $client = $element.querySelector('.client')
        let $excerpt = $element.querySelector('.excerpt')
        if($excerpt == null) {
            $excerpt = []
        }
        const tl = new TimelineLite()

        tl.add([
            TweenLite.fromTo($client , .6 , { y : 15 , autoAlpha : 0} ,  { y : 0 , autoAlpha : 1 , ease : Power4.easeOut}),
            TweenLite.fromTo($title , .6 , { y : 15 , autoAlpha : 0} ,  { y : 0 , autoAlpha : 1 , ease : Power4.easeOut , delay : .2 }),
            TweenLite.fromTo($excerpt , .6 , { y : 15 , autoAlpha : 0} ,  { y : 0 , autoAlpha : 1 , ease : Power4.easeOut , delay : .3 }),
            TweenLite.fromTo($fill , .6 , { x : '0%' } ,  { x : '-101%' , ease : Cubic.easeInOut , delay : .3 }),
            TweenLite.fromTo($bg , 1.2 , { scale : 1.05 } ,  { scale : 1.01 , ease : Cubic.easeOut , delay : .35 })
        ],'+=.8')

    }
}

const page = Barba.BaseView.extend({
    namespace: 'workslist',
    node: undefined,
    onEnter: function() {
        this.node = new Works(this.container)
        Env.$html.classList.add(this.node.classView)
    },
    onEnterCompleted:function(){
        this.node.finalize()
    },
    onLeave: function()
    {
        this.node.destroy()
    },
    onLeaveCompleted: function()
    {
        if(this.container.getAttribute('data-namespace') != this.namespace)
        {
            Env.$html.classList.remove(this.node.classView)
        }
    }
});

export default page;