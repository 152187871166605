import Barba from "barba.js";
import Env from "../base/env.js";
import Map from "../modules/Map";
import Mailto from "../modules/Mailto";
import GlVisual from "../gl/glVisual";
import TweenMax from "gsap/TweenMax";
import imagesLoaded from "imagesloaded";
import Channels from "../base/channels";

class Contact {
  constructor(view = "") {
    this.view = view;
    this.classView = "currentView--contact";
    // this.$map = this.view.querySelector('.jsMap--view');
    // this.imgsToPreload = this.view.querySelectorAll('.jsPreload--img')
    new Mailto(this.view);
    this.funcLaunch = this.intro.bind(this);
    if (Env.framework.UI.launcher.LAUNCHED == false) this.channels();
    this.setup();
  }

  channels() {
    Channels.on("launcher::exit", this.funcLaunch);
  }

  destroy() {
    Channels.removeListener("launcher::exit", this.funcLaunch);
    // this.glVisual.destroy()
  }

  setup() {
    // this.imgLoaded = imagesLoaded(this.imgsToPreload,()=>{
    //     new Map(this.$map,{ lat: this.$map.getAttribute('data-lat')*1 , lng:this.$map.getAttribute('data-lng')*1 })
    //     this.glVisual = new GlVisual(this.view.querySelector('.jsVisual--breath'),this.view.querySelector('.jsRef--breath'))
    // })
  }

  intro() {
    const $fill = this.view.querySelector(".fill");

    let timeline = new TimelineLite();
    timeline.add([
      TweenMax.fromTo(
        $fill,
        1,
        { autoAlpha: 1 },
        { autoAlpha: 0, ease: Cubic.easeOut }
      ),
    ]);
  }

  finalize() {
    setTimeout(() => {
      if (Env.isVisiting === true) {
        this.introFn = this.intro();
      }
    }, 250);
  }
}

const page = Barba.BaseView.extend({
  namespace: "contact",
  node: undefined,
  onEnter: function () {
    this.node = new Contact(this.container);
    Env.$html.classList.add(this.node.classView);
  },
  onEnterCompleted: function () {
    this.node.finalize();
    Env.$html.classList.add("skin--isDark");
  },
  onLeave: function () {
    this.node.destroy();
  },
  onLeaveCompleted: function () {
    if (this.container.getAttribute("data-namespace") != this.namespace) {
      Env.$html.classList.remove(this.node.classView);
    }
  },
});

export default page;
