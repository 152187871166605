import Env from "../base/env";
import Channels from "../base/channels";
import { TweenMax } from "gsap";
import Hammer from "hammerjs";
import WheelIndicator from "wheel-indicator";

export default class HomeSlideshowV2 {
  constructor(view = "") {
    // Dom
    this.view = view;
    this.$collection = this.view.querySelector(".jsCollection");
    this.$filler = this.view.querySelector(".jsFiller");
    this.$fillerTransition = this.view.querySelector(".jsFiller--transition");
    this.$items = this.view.querySelectorAll(".jsItem");
    this.$previous = this.view.querySelector(".jsPrevious");
    this.$next = this.view.querySelector(".jsNext");
    this.$triggers = this.view.querySelectorAll(".jsAnchor");
    // Obj
    this.skew = { duration: 0.7, easing: Cubic.easeInOut };
    // Touch
    this.indicator = false;
    this.hammerView = new Hammer(this.view);
    this.hammerView.get("swipe").set({ direction: Hammer.DIRECTION_VERTICAL });
    // States
    this.OLD_INDEX = 0;
    this.CURRENT_INDEX = 0;
    this.TOTAL = this.$items.length - 1;
    this.DIRECTION = 0;
    this.IS_MOVING = false;
    this.CAN_AUTOPLAY = false;
    this.CURRENT_MODE = false;
    // Autoplay
    this.durationAutoplay = 4;
    this.timerAutoplay = false;
    // Fns
    this.funcReset = this.reset.bind(this);
  }

  autoplay() {
    if (this.CAN_AUTOPLAY) {
      this.timerAutoplay = window.setTimeout(() => {
        this.goToNext();
      }, this.durationAutoplay * 1000);
    }
  }

  destroy() {
    Channels.removeListener("window::smartresize", this.funcReset);
  }

  events() {
    this.indicator = new WheelIndicator({
      elem: this.view,
      callback: (e) => {
        if (e.direction == "down") {
          this.goToNext();
        } else {
          this.goToPrevious();
        }
      },
    });
    this.indicator.getOption("preventMouse");

    if (this.$previous !== null) {
      this.$previous.addEventListener("click", () => {
        this.goToPrevious();
      });
    }

    if (this.$next !== null) {
      this.$next.addEventListener("click", () => {
        this.goToNext();
      });
    }

    // SWIPE
    this.hammerView.on("swipeup", (ev) => {
      this.goToNext();
    });

    this.hammerView.on("swipedown", (ev) => {
      this.goToPrevious();
    });

    if (this.$triggers.length) {
      for (let i = 0; i < this.$triggers.length; i++) {
        const $trigger = this.$triggers[i];
        $trigger.addEventListener("click", () => {
          this.goTo(i);
        });
      }
    }

    Channels.on("window::smartresize", this.funcReset);
  }

  finalize() {
    console.log(this.CURRENT_MODE);
    if (this.$items.length > 0) {
      const $itemActive = this.$items[this.CURRENT_INDEX];
      for (let i = 0; i < this.$items.length; i++) {
        const item = this.$items[i];
        if (item.classList.contains("disabled") == true)
          item.classList.remove("disabled");
        if (item.classList.contains("enabled") == true)
          item.classList.remove("enabled");
        item.style.zIndex = 0;
        if (i != this.CURRENT_INDEX) {
          item.classList.add("disabled");
        }
      }
      $itemActive.classList.add("enabled");
      $itemActive.style.zIndex = 1;

      if (this.CURRENT_MODE == "video") {
        const $getVideo = $itemActive.querySelector(".jsVideoLoop");
        $getVideo.play();
        this.$next.classList.add("for--video");
      } else {
        this.$next.classList.remove("for--video");
      }
    }
    if (this.CURRENT_MODE == "project") {
      this.$next.classList.add("for--project");
    } else {
      this.$next.classList.remove("for--project");
    }
    if (this.CURRENT_MODE == "manifesto") {
      this.$next.classList.add("for--manifesto");
    } else {
      this.$next.classList.remove("for--manifesto");
    }
    if (this.CURRENT_MODE == "footer") {
      this.$next.classList.add("for--footer");
    } else {
      this.$next.classList.remove("for--footer");
    }
    this.updateTrigger();
  }

  goTo(index) {
    if (this.IS_MOVING == false) {
      clearTimeout(this.timerAutoplay);
      if (index > this.CURRENT_INDEX) {
        this.DIRECTION = 1;
      } else {
        this.DIRECTION = -1;
      }
      this.CURRENT_INDEX = index;
      this.move();
    }
  }

  goToPrevious() {
    if (this.IS_MOVING == false) {
      clearTimeout(this.timerAutoplay);
      this.DIRECTION = -1;
      this.CURRENT_INDEX--;
      if (this.CURRENT_INDEX < 0) {
        this.CURRENT_INDEX = this.TOTAL;
      }
      this.move();
    }
  }

  getInitialType() {
    const $next = this.$items[this.CURRENT_INDEX];
    const getNextType = $next.getAttribute("data-type");
    this.CURRENT_MODE = getNextType;
  }

  goToNext() {
    if (this.IS_MOVING == false) {
      clearTimeout(this.timerAutoplay);
      this.DIRECTION = 1;
      this.CURRENT_INDEX++;
      if (this.CURRENT_INDEX > this.TOTAL) {
        this.CURRENT_INDEX = 0;
      }
      this.move();
    }
  }

  intro() {
    const $current = this.$items[this.CURRENT_INDEX];
    const getType = $current.getAttribute("data-type");

    if (getType == "logo") {
      const $logo = $current.querySelector(".logo");
      TweenLite.fromTo(
        $logo,
        1,
        { autoAlpha: 0, scale: 0.96 },
        { autoAlpha: 1, scale: 1, ease: Cubic.easeOut }
      );
    } else if (getType == "video") {
      const $video = $current.querySelector(".jsVideoLoop");
      $video.play();

      const $texts = [
        $current.querySelector("h1"),
        $current.querySelectorAll("p"),
      ];

      TweenMax.staggerFromTo(
        $texts,
        1,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: Cubic.easeOut, delay: 0.2 },
        0.1
      );
    }
  }

  init() {
    const $current = this.$items[this.CURRENT_INDEX];
    const $texts = [
      $current.querySelector("h1"),
      $current.querySelectorAll("p"),
    ];
    TweenLite.set($texts, { autoAlpha: 0 });
  }

  move() {
    const $current = this.$items[this.OLD_INDEX];
    const $next = this.$items[this.CURRENT_INDEX];
    const getNextType = $next.getAttribute("data-type");
    let timeline = new TimelineLite({
      onComplete: this.twIsComplete.bind(this),
    });
    const viewport = Env.framework.toolbox.getViewport();
    let yFromStart, yFromEnd, skewXFrom;

    if (this.DIRECTION > 0) {
      yFromStart = "100%";
      yFromEnd = "-100%";
      skewXFrom = "15deg";
    } else {
      yFromStart = "-100%";
      yFromEnd = "100%";
      skewXFrom = "-15deg";
    }

    if (this.OLD_INDEX != this.CURRENT_INDEX) {
      this.CURRENT_MODE = getNextType;
      this.IS_MOVING = true;
      this.updating();
      timeline.kill();
      timeline.add(() => {
        this.$fillerTransition.style.zIndex = 5;
        if (getNextType == "manifesto") {
          $next.querySelector(".content").classList.remove("is--animated");
        }
      });
      timeline.add([
        TweenLite.fromTo(
          this.$fillerTransition,
          this.skew.duration,
          { y: yFromStart },
          {
            y: "0%",
            skewY: skewXFrom,
            ease: this.skew.easing,
            skewType: "simple",
          }
        ),
        TweenLite.to(this.$fillerTransition, this.skew.duration / 2, {
          skewY: "0deg",
          ease: this.skew.easing,
          skewType: "simple",
          delay: this.skew.duration / 2,
        }),
      ]);
      timeline.add(() => {
        this.updateProgress();
        clean();
        TweenLite.set(this.$fillerTransition, { clearProps: "y,skewY" });
      });
      timeline.add([
        TweenLite.fromTo(
          this.$fillerTransition,
          this.skew.duration,
          { y: "0%" },
          {
            y: yFromEnd,
            skewY: skewXFrom,
            ease: this.skew.easing,
            skewType: "simple",
            immediateRender: false,
          }
        ),
        TweenLite.to(this.$fillerTransition, this.skew.duration / 2, {
          skewY: "0deg",
          ease: this.skew.easing,
          skewType: "simple",
          delay: this.skew.duration / 2,
          immediateRender: false,
        }),
      ]);
      if (getNextType == "project") {
        const $title = $next.querySelector(".title");
        const $client = $next.querySelector(".client");
        const $btn = $next.querySelector(".btn-link");
        timeline.add(
          [
            TweenLite.fromTo(
              $client,
              0.6,
              { autoAlpha: 0, y: 20 },
              { autoAlpha: 1, y: 0, ease: Cubic.easeOut }
            ),
            TweenLite.fromTo(
              $title,
              0.6,
              { autoAlpha: 0, y: 20, scaleY: 1.06 },
              { autoAlpha: 1, y: 0, scaleY: 1, ease: Cubic.easeOut, delay: 0.1 }
            ),
            TweenLite.fromTo(
              $btn,
              0.6,
              { autoAlpha: 0, y: 20 },
              { autoAlpha: 1, y: 0, ease: Cubic.easeOut, delay: 0.2 }
            ),
          ],
          "-=.4"
        );
      } else if (getNextType == "video") {
        const $content = $next.querySelector(".inner");
        timeline.add(
          [
            TweenLite.fromTo(
              $content,
              1,
              { autoAlpha: 0, scale: 0.98 },
              {
                autoAlpha: 1,
                scale: 1,
                ease: Cubic.easeOut,
                onComplete: () => {
                  $content.classList.add("is--animated");
                },
              }
            ),
          ],
          "-=.2"
        );
      } else if (getNextType == "manifesto" || getNextType == "prez") {
        const $content = $next.querySelector(".content");
        timeline.add(
          [
            TweenLite.fromTo(
              $content,
              1,
              { autoAlpha: 0, y: 20, scale: 0.98 },
              {
                autoAlpha: 1,
                scale: 1,
                ease: Cubic.easeOut,
                onComplete: () => {
                  $content.classList.add("is--animated");
                },
              }
            ),
          ],
          "-=.2"
        );
      } else if (getNextType == "logo") {
        const $logo = $next.querySelector(".logo");
        timeline.add(
          [
            TweenLite.fromTo(
              $logo,
              1,
              { autoAlpha: 0, scale: 0.96 },
              { autoAlpha: 1, scale: 1, ease: Cubic.easeOut }
            ),
          ],
          "-=.4"
        );
      } else if (getNextType == "footer") {
        setTimeout(() => {
          $next.querySelector(".jsFooter").classList.add("is--shown");
        }, 800);
      }
      timeline.add(() => {
        this.autoplay();
        this.$fillerTransition.style.zIndex = 0;
      });

      function clean() {
        $current.style.zIndex = 2;
        $next.style.zIndex = 3;
        $next.classList.remove("disabled");
        $next.classList.add("enabled");
      }
    }
  }

  reset() {}

  setup() {
    this.events();
    this.getInitialType();
    this.updating();
    this.finalize();
    this.videoActions();

    if (this.TOTAL > 0) {
      setTimeout(() => {
        this.autoplay();
      }, 1100);
    }
  }

  twIsComplete() {
    this.IS_MOVING = false;
    this.OLD_INDEX = this.CURRENT_INDEX;
    this.finalize();
  }

  updateTrigger() {
    if (this.$triggers.length > 0) {
      const $triggerActive = this.$triggers[this.CURRENT_INDEX];
      for (let i = 0; i < this.$triggers.length; i++) {
        const $trigger = this.$triggers[i];
        $trigger.classList.remove("is--active");
      }
      $triggerActive.classList.add("is--active");
    }
  }

  updateProgress() {
    this.updateTrigger();
  }

  updating() {
    const $current = this.$items[this.OLD_INDEX];
    const getCurrentType = $current.getAttribute("data-type");
    if (getCurrentType == "video") {
      const $getVideo = $current.querySelector(".jsVideoLoop");
      $getVideo.pause();
      setTimeout(() => {
        $getVideo.currentTime = 0;
      }, 700);
    } else if (getCurrentType == "footer") {
      $current.querySelector(".jsFooter").classList.remove("is--shown");
    }
    if (this.CURRENT_INDEX > 0) {
      this.view.classList.add("is--scrolling");
    } else {
      this.view.classList.remove("is--scrolling");
    }
  }

  videoActions() {
    const $getVideos = this.view.querySelectorAll(".jsVideoLoop");
    for (const $video of $getVideos) {
      const $btnSound = $video.parentElement.querySelector(".jsVideo--sound");
      $btnSound.addEventListener("click", () => {
        if ($video.muted == false) {
          $video.muted = true;
          $btnSound.classList.add("is--disabled");
        } else {
          $video.muted = false;
          $btnSound.classList.remove("is--disabled");
        }
      });
    }
  }
}
