export default class AudioPlayer
{
    constructor(view)
    {
        if(view == null || typeof SC === "undefined") return false
        this.view = view
        this.player = SC.Widget(this.view.querySelector('iframe.sc-widget'))
        this.$btnPlay = this.view.querySelector('.jsPlay')
        this.$progression = this.view.querySelector('.jsProgression')
        this.playerData = { getPaused : -1 , IS_LOADED: false }
        this.events()
    }

    events()
    {
        this.$btnPlay.addEventListener('click',()=>{
            this.playPause()
        })

        this.player.bind(SC.Widget.Events.READY,()=>{
            this.loadInfo()
        })

        this.player.bind(SC.Widget.Events.PLAY_PROGRESS,(e)=>{
            this.getProgress(e)
        })

        this.player.bind(SC.Widget.Events.FINISH,()=>{
            this.restart()
        })

        // Force loading if event ready failed
        setTimeout(()=>{
            // console.log(this.playerData.IS_LOADED)
            if(this.playerData.IS_LOADED == false) {
                this.loadInfo()
            }
        },1000)

    }

    getProgress(progress)
    {
        this.$progression.style.width = `${progress.relativePosition * 100}%`
    }

    loadInfo()
    {
        this.player.getCurrentSound((song)=>{
            this.view.querySelector('.jsVisual').setAttribute('src',song.artwork_url)
            this.view.querySelector('.jsLink').setAttribute('href',song.permalink_url)
            this.view.querySelector('.jsAuthor').innerHTML = song.user.username
            this.view.querySelector('.jsTitle').innerHTML = song.title

            this.playerData.IS_LOADED = true
        })

        this.player.getDuration((duration)=>{
            this.playerData.duration = duration
        })

        this.$progression.style.backgroundColor = this.view.getAttribute('data-color')

    }

    playPause()
    {
        const status = this.playerData.getPaused

        this.player.isPaused((bool)=>{
            const status = bool
            this.playerData.getPaused = status;
            if(status )
            {
                this.view.classList.remove('is--pausing')
                this.view.classList.add('is--playing')
                this.player.play();
            }
            else
            {
                this.view.classList.remove('is--playing')
                this.view.classList.add('is--pausing')
                this.player.pause();
            }
        });
    }

    restart()
    {
        this.view.classList.remove('is--playing')
        this.view.classList.remove('is--pausing')
        this.player.seekTo(0)
    }
}