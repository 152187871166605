"use strict";
import { TweenMax } from "gsap";

// MODULES
import OuterHeaven from "./base/outerheaven.js";
import BrowserDetection from "./base/detection.js";
import Env from "./base/env.js";
import Toolbox from "./base/toolbox.js";
import Channels from "./base/channels.js";
import Home from "./view/home.js";
import Works from "./view/workslist.js";
import WorksSingle from "./view/works-single.js";
import Agency from "./view/agency.js";
import Contact from "./view/contact.js";
import Merci from "./view/merci.js";
import UI from "./view/ui.js";
import shuffle from "shuffle-array";
import MainTransition from "./modules/MainTransition";

document.addEventListener("DOMContentLoaded", function () {
  // homeContent.slideshow = shuffle(homeContent.slideshow)
  // Starter
  Env.framework.toolbox = new Toolbox();
  new BrowserDetection();

  window.addEventListener("resize", function () {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function () {
      Channels.emit("window::smartresize");
    }, 200);
  });

  // Views
  Env.framework.MainTransition = new MainTransition();
  Env.framework.UI = new UI();
  Home.init();
  Works.init();
  WorksSingle.init();
  Agency.init();
  Contact.init();
  Merci.init();

  // Signature
  if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
    var args = [
      "\n %c with <3 by @jcsuzanne \u2730 http://www.jcsuzanne.com/ \n\n",
      "background: #ffcc33; padding:5px 0;color: #333333;",
    ];
    window.console.log.apply(console, args);
  } else if (window.console) {
    window.console.log("@jcsuzanne - http://www.jcsuzanne.com/");
  }

  // Start Barba
  new OuterHeaven();
});
