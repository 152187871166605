export default class Mailto
{
    constructor(view)
    {
        this.view = view
        this.$triggers = this.view.querySelectorAll('.jsTrigger-mailto')
        this.draw()
    }

    draw()
    {
        for(let i = 0; i < this.$triggers.length; i++) {
            const $trigger = this.$triggers[i]
            const getAddress = $trigger.getAttribute('data-mailto')
            const realAddress = getAddress.replace('[at]','@')
            $trigger.innerHTML = realAddress
            $trigger.addEventListener('click',()=>{
                window.location.href=`mailto:${realAddress}`
            })
        }
    }
}