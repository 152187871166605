import Env from "../base/env.js";
import imagesLoaded from "imagesloaded";
import SplitText from "../vendor/gsap-bonus/SplitText";
import Channels from "../base/channels";
import "babel-polyfill";

class Launcher {
  constructor() {
    this.view = Env.$mainLoader;
    this.LAUNCHED = false;
    this.tlIn = new TimelineLite();
    this.tlOut = new TimelineLite();
    this.$logo = this.view.querySelector(".jsLogo");
    this.$fill = this.view.querySelector(".jsFill");
    this.$fillSmall = this.view.querySelector(".jsFillSmall div");
    this.$visual = this.view.querySelector(".jsVisual");
    this.$content = this.view.querySelector(".jsContent");
    this.$title = this.view.querySelector(".jsTitle");
    this.$tagline = this.view.querySelector(".jsTagline");
    this.$progress = this.view.querySelector(".jsProgress");
    this.splitTitle = new SplitText(this.$title, { type: "chars" });
    this.splitTagline = new SplitText(this.$tagline, { type: "words" });
    // this.imgsToPreload = []
    // this.toPreload = [
    //     config.pathAssets+'/img/menu/blue.png',
    //     config.pathAssets+'/img/menu/red.png',
    //     config.pathAssets+'/img/menu/yellow.png',
    //     config.pathAssets+'/img/dmaps/2048x2048/clouds.jpg',
    //     config.pathAssets+'/img/dmaps/lake.jpg',
    //     config.pathAssets+'/img/home/compo1/foreground.png',
    //     config.pathAssets+'/img/home/compo1/background.png',
    //     config.pathAssets+'/img/home/compo1/foreground-dmap.jpg',
    //     config.pathAssets+'/img/home/compo1/foreground-mask.jpg',
    //     config.pathAssets+'/img/home/compo2/foreground.png',
    //     config.pathAssets+'/img/home/compo2/background.png',
    //     config.pathAssets+'/img/home/compo2/foreground-dmap.jpg',
    //     config.pathAssets+'/img/home/compo2/foreground-mask.jpg',
    //     config.pathAssets+'/img/home/compo3/foreground.png',
    //     config.pathAssets+'/img/home/compo3/background.png',
    //     config.pathAssets+'/img/home/compo3/foreground-dmap.jpg',
    //     config.pathAssets+'/img/home/compo3/foreground-mask.jpg',
    //     config.pathAssets+'/img/home/compo4/foreground.png',
    //     config.pathAssets+'/img/home/compo4/background.png',
    //     config.pathAssets+'/img/home/compo4/foreground-dmap.jpg',
    //     config.pathAssets+'/img/home/compo4/foreground-mask.jpg',
    // ]
    // for(let $item of homeContent.slideshow) {
    //     this.toPreload.push($item.visual)
    // }
    // for(let i = 0; i < this.toPreload.length; i++) {
    //     const img = document.createElement('img')
    //     img.setAttribute('src',this.toPreload[i])
    //     this.imgsToPreload.push(img)
    // }
    // this.TOTAL_PRELOAD = this.toPreload.length
    this.NO_LAUNCH = false;
    const getController = Env.framework.toolbox.getController();
    if (getController == "merci" || getController == "contact") {
      this.NO_LAUNCH = true;
    }
    this.init();
  }

  completeIn() {
    let progress = 0;
    const scaleMax = 0.6;
    this.imgLoaded = new imagesLoaded(this.imgsToPreload, () => {
      setTimeout(() => {
        this.out();
      }, 500);
    });
    this.imgLoaded.on("progress", (instance, image) => {
      progress++;
      const valueProgress = (progress / this.TOTAL_PRELOAD) * scaleMax;
      this.$progress.style.transform = `scaleX(${valueProgress})`;
      this.$progress.style.webkitTransform = `scaleX(${valueProgress})`;
    });
  }

  completeInNoPreload() {
    setTimeout(() => {
      this.out();
    }, 500);
  }

  completeOut() {
    this.LAUNCHED = true;
    Env.$master.removeChild(this.view);
  }

  enter() {
    this.tlIn.add(() => {
      TweenLite.set(this.$content, { autoAlpha: 1 });
    });
    this.tlIn.add([
      TweenLite.to(this.$logo, 0.5, { opacity: 1, ease: Cubic.easeOut }),
      TweenLite.to(this.$fillSmall, 1, { x: "0%", ease: Power4.easeInOut }),
      TweenLite.fromTo(
        this.$visual,
        1,
        { y: "-40%" },
        { opacity: 1, y: "-50%", ease: Cubic.easeInOut }
      ),
    ]);
    this.tlIn.add(
      [
        TweenMax.staggerFromTo(
          this.splitTitle.chars,
          0.5,
          { opacity: 0, x: 10 },
          { opacity: 1, x: 0, ease: Power2.easeOut },
          0.03
        ),
        TweenMax.staggerFromTo(
          this.splitTagline.words,
          0.7,
          { opacity: 0 },
          { opacity: 1, ease: Cubic.easeOut },
          0.03
        ),
      ],
      "-=.4"
    );
    this.tlIn.add(
      [
        TweenMax.staggerFromTo(
          this.splitTagline.words,
          1,
          { x: 120 },
          { x: 0, ease: Cubic.easeOut },
          0.03
        ),
      ],
      "-=.85"
    );
    this.tlIn.add(() => {
      this.completeInNoPreload();
    });
  }

  noLaunch() {
    Env.$master.removeChild(this.view);
    setTimeout(() => {
      Env.$html.classList.add("ui--isReady");
      Channels.emit("launcher::exit");
      this.LAUNCHED = true;
    }, 150);
  }

  out() {
    const logoTiming = Env.mobileCSS == true ? "-=.8" : "-=.1";
    this.tlOut.add(() => {
      this.$progress.classList.add("no--animated");
    });
    this.tlOut.add([
      TweenLite.to(this.$progress, 0.5, { scaleX: 1, ease: Sine.easeOut }),
    ]);
    this.tlOut.add(
      [
        TweenLite.to(this.$progress.parentElement, 0.5, {
          y: "100%",
          ease: Power4.easeOut,
        }),
      ],
      "+=.5"
    );
    this.tlOut.add(
      [
        TweenLite.to(this.$visual, 0.6, {
          opacity: 0,
          y: "-60%",
          ease: Cubic.easeIn,
        }),
        TweenMax.staggerTo(
          this.splitTitle.chars,
          0.5,
          { opacity: 0, x: -10, ease: Power2.easeIn },
          0.03
        ),
        TweenMax.staggerTo(
          this.splitTagline.words,
          0.7,
          { opacity: 0, x: -50, ease: Cubic.easeIn },
          0.03
        ),
      ],
      "+=.1"
    );
    this.tlOut.add(
      [
        TweenLite.to(this.$fillSmall, 0.5, {
          x: "-100%",
          ease: Power4.easeInOut,
        }),
        TweenMax.fromTo(
          this.$fill,
          1,
          {
            x: "0%",
          },
          {
            x: "-100%",
            skewX: "30deg",
            ease: Power2.easeInOut,
            delay: 0,
            skewType: "simple",
            onStart: () => {
              const getController = Env.framework.toolbox.getController();
              const time = getController == "home" ? 500 : 0;
              setTimeout(() => {
                Channels.emit("launcher::exit");
              }, time);
            },
          }
        ),
        TweenMax.to(this.$fill, 0.5, {
          skewX: "0deg",
          ease: Power2.easeInOut,
          skewType: "simple",
          delay: 0.5,
        }),
      ],
      "-=.3"
    );
    this.tlOut.add(
      [TweenLite.to(this.$logo, 0.1, { opacity: 0, ease: Cubic.easeOut })],
      logoTiming
    );
    this.tlOut.add(() => {
      this.completeOut();
    });
  }

  init() {
    Env.framework.toolbox.resetScroll();
    if (this.NO_LAUNCH) {
      this.noLaunch();
    } else {
      setTimeout(() => {
        this.enter();
      }, 350);
    }
  }
}

export default Launcher;
