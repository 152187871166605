import Barba from 'barba.js'
import Env from '../base/env.js'
import AudioPlayer from '../modules/AudioPlayer'
import SSHorizontal from '../modules/v2-ss-horizontal'
import Fx from '../modules/Fx'
import GlNext from '../gl/glNext'
import GlCover from '../gl/glCover'
import SplitText from '../vendor/gsap-bonus/SplitText'
import imagesLoaded from 'imagesloaded'
import 'babel-polyfill'

class WorksSingle
{
    constructor(view='')
    {
        this.view = view
        // DOM
        this.$next = this.view.querySelector('.jsScrollNext--view')
        //
        this.classView = 'currentView--worksSingle'
        this.imgsToPreload = this.view.querySelectorAll('.jsPreload--img')
        this.waypointsInstances = []
        // Flags
        this.ENABLE_AUTONEXT = false
        this.IS_NEXT_CHECKING = false
        this.IS_WATCHING = true
        // Timers
        this.timerNext = false
        // Fns
        setTimeout(() => {
            this.adjustPrez()
        }, 500);

        this.events()
        this.setup()
    }

    destroy()
    {
        this.fx.destroy()
        setTimeout(() => {
            this.glCover.destroy()
        }, 600);

        for(const module of this.modulesSlideshow){
            module.destroy()
        }
        for ( const e of this.waypointsInstances )
        {
            e.destroy()
        }
        this.view.classList.add('is--leaving')
    }

    events()
    {
        if(this.view.querySelector('.jsScrollNext--view') != null)
        {
            this.view.querySelector('.jsScrollNext--view .jsScrollNext--link').addEventListener('click',()=>{
                Env.framework.UI.launchAfter()
            })
        }

    }

    finalize()
    {
        setTimeout(() => {
            this.audio = new AudioPlayer(this.view.querySelector('.jsAudioPlayer--view'))
        }, 1000);
        this.fx = new Fx(this.view,this.watcher.bind(this))
    }

    adjustPrez()
    {
        if(this.view.querySelector('.works-single__presentation') != null)
        {
            const $titles = this.view.querySelectorAll('.works-single__presentation h2')
            const color = this.view.querySelector('.works-single__presentation').getAttribute('data-color')
            if($titles.length > 0) {
                for(let i = 0; i<$titles.length; i++) {
                    const $title = $titles[i]
                    let WAS_ANIMATED = false
                    let timelineQuote = new TimelineLite({paused:true})
                    const split = new SplitText($title , {type:"lines", linesClass:"mask"})
                    let innerLine = [];
                    for(let j = 0; j < split.lines.length;j++) {
                        const splitLine = split.lines[j]
                        innerLine.push(new SplitText(splitLine , {type:"lines" , linesClass:"text"}))
                    }

                    const tweenLine = []
                    for(let w = 0; w < innerLine.length ; w++) {
                        const colorBand = document.createElement('div')
                        colorBand.style.backgroundColor = color
                        colorBand.classList.add('colorband')
                        tweenLine.push(innerLine[w].lines[0])
                        innerLine[w].lines[0].appendChild(colorBand)
                    }
                    timelineQuote.add([
                        TweenMax.staggerFromTo(tweenLine, 1, { y : '100%'} , { y:'0%' , ease:Expo.easeOut}, 0.1),
                    ])

                    const instance = new Waypoint({
                        element: $title,
                        handler: (_direction)=> {
                            if(WAS_ANIMATED == false)
                            {
                                timelineQuote.restart()
                                WAS_ANIMATED = true
                                instance.disable()
                            }
                        },
                        offset: '75%'
                    });
                    this.waypointsInstances.push(instance);
                }
            }
            const $imgs = this.view.querySelectorAll('.works-single__copy .description > img')
            if($imgs.length > 0) {
                for(let i = 0; i<$imgs.length; i++) {
                    const $img = $imgs[i]
                    $img.classList.add('jsWaypoint--appearReveal')
                    $img.setAttribute('data-offset','70%')
                }
            }
        }
    }

    featuresAccordion()
    {
        const $ref = this.view.querySelector('.jsFeatures--view')
        const $triggers = $ref.querySelectorAll('.jsTrigger')
        for(let $trigger of $triggers) {

            $trigger.addEventListener('click',()=>{
                $trigger.classList.toggle('is--active')
                $trigger.parentElement.querySelector('.jsReceptor').classList.toggle('is--active')
            })
        }
    }

    setup()
    {
        if(Env.mobileCSS == false) {
            this.glCover = new GlCover(this.view.querySelector('.works-single__cover'))
        }
        if(Env.mobileCSS == true) {
            this.featuresAccordion()
        }
        this.imgLoaded = imagesLoaded(this.imgsToPreload,()=>{
            this.modulesSlideshow = this.slideshow()
        })
    }

    slideshow()
    {
        let modules = []
        for(const $ref of this.view.querySelectorAll('.jsSSHorizontal--view')){
            const module = new SSHorizontal($ref)
            modules.push(module)
        }
        return modules
    }

    watcher()
    {
        if(this.ENABLE_AUTONEXT == true && typeof this.$next !== "undefined") {
            const scroll = Env.framework.toolbox.scrollPosition()
            const ratio = scroll.scrollY / scroll.fullPageHeight
            if(ratio >= 1 && this.IS_NEXT_CHECKING === false) {
                this.IS_NEXT_CHECKING = true
                this.$next.classList.add('is--waiting')
                this.timerNext = setTimeout(() => {
                    this.view.querySelector('.jsScrollNext--link').click()
                }, 1500);
            } else if(ratio < 1 && this.IS_NEXT_CHECKING === true) {
                this.$next.classList.remove('is--waiting')
                this.IS_NEXT_CHECKING = false
                clearTimeout(this.timerNext)
            }
        }
        // Mod Slideshow
        if(typeof this.modulesSlideshow != 'undefined') {
            for(let mod of this.modulesSlideshow) {
                mod.onWatch()
            }
        }

    }

}

const page = Barba.BaseView.extend({
    namespace: 'worksSingle',
    node: undefined,
    onEnter: function() {
        this.node = new WorksSingle(this.container)
        Env.$html.classList.add(this.node.classView)
    },
    onEnterCompleted: function(){
        this.node.finalize()
    },
    onLeave: function()
    {
        this.node.destroy()
    },
    onLeaveCompleted: function()
    {
        if(this.container.getAttribute('data-namespace') != this.namespace)
        {
            Env.$html.classList.remove(this.node.classView)
        }
    }
});

export default page;