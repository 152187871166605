import Env from '../base/env.js'
import normalizeWheel from 'normalize-wheel'
import requestAnimFrame from '../vendor/raf'
import Channels from '../base/channels'

export default class UISwitcher
{
    constructor()
    {
        this.direction = 0
        this.delta = 5
        this.heightUI= Env.$master.querySelector('.ui').getBoundingClientRect().height
        this.lastScrollYPosition = 0
        this.raf = undefined
        this.touch = { start: 0 , end: 0}
        this.DIDSCROLL = false;
        this.wheelEvent = Env.framework.toolbox.wheelSupport()
        this.events()
        this.watcher()
    }

    events()
    {
        Env.$body.addEventListener(this.wheelEvent,(event)=> {
            const wheel = normalizeWheel(event)
            this.direction = wheel.pixelY
            this.DIDSCROLL = true
            Channels.emit('window::scrolling',wheel)
        });
        document.addEventListener('touchstart',(event)=>{
            this.touch.start = event.targetTouches[0].pageY
        })
        document.addEventListener('touchmove',(event)=>{
            this.touch.end = event.targetTouches[0].pageY

            if(this.touch.start>this.touch.end)
            {
                this.direction = 1
            }
            else
            {
                this.direction = -1
            }
            this.DIDSCROLL = true
        })
    }

    toggleShowHide()
    {
        const position = Env.framework.toolbox.scrollPosition().scrollY

        if(Math.abs(this.lastScrollYPosition - position) <= this.delta) return;

        if (position > this.lastScrollYPosition)
        {
            if(position > this.heightUI && Env.$html.classList.contains('ui--show'))
            {
                if(Env.$html.classList.contains('mainnav--isOpened') == false)
                {
                    Env.$html.classList.add('ui--animExit')
                    setTimeout(function() {
                        Env.$html.classList.remove('ui--animExit')
                    }, 450);
                }
            }
            Env.$html.classList.remove('ui--show')
        }
        else
        {
            if(position + Env.framework.toolbox.getViewport().height < Env.$master.getBoundingClientRect().height)
            {
                Env.$html.classList.add('ui--show')
            }
        }
        this.lastScrollYPosition = position
    }

    watcher()
    {
        this.raf = requestAnimFrame(()=>{
            this.watcher()
        })

        if(this.DIDSCROLL)
        {
            this.toggleShowHide()
            this.DIDSCROLL = false
        }

        const position = Env.framework.toolbox.scrollPosition()
        if(position.scrollY > 0)
        {
            if(Env.$html.classList.contains('v--isScrolling') == false)
            {
                Env.$html.classList.add('v--isScrolling')
            }
        }
        else
        {
            if(Env.$html.classList.contains('v--isScrolling') == true)
            {
                Env.$html.classList.remove('v--isScrolling')

            }
            Env.$html.classList.remove('ui--show')
        }
        if(position.scrollY > this.heightUI)
        {
            Env.$html.classList.add('ui--sticky')
        }
        else
        {
            if(position.scrollY == 0)
            {
                Env.$html.classList.remove('ui--sticky')
            }
        }
    }
}