import requestAnimFrame from '../vendor/raf'
import cancelRequestAnimFrame from '../vendor/stopraf'
import TweenMax from 'gsap/TweenMax'
import Env from '../base/env'
import FlowWaypoints from '../modules/FlowWaypoints'
import FlowParallax from '../modules/FlowParallax'
import Channels from '../base/channels'
import 'babel-polyfill'

export default class Fx
{
    constructor(view,fnWatch)
    {
        this.view = view
        this.$appearReady = this.view.querySelector('.jsReady--appearReveal')
        this.$letters = this.view.querySelectorAll('.jsParallax--letter')
        this.funcLetterAgency = this.lettersAgency.bind(this)
        // Params
        this.funcWatcher = fnWatch
        //
        this.raf = undefined
        this.position = undefined
        // Fns
        this.setup()
    }

    destroy()
    {
        if(typeof this.raf != "undefined") {
            cancelRequestAnimFrame(this.raf)
        }
        if(typeof this.flowParallax != "undefined") {
            this.flowParallax.destroy()
        }
        if(typeof this.waypoints != "undefined")
        {
            this.waypoints.destroy();
        }
        if(this.$letters.length > 0) {
            Channels.removeListener('watcher::raf', this.funcLetterAgency)
        }
        if(typeof this.funcWatcher !== "undefined") {
            Channels.removeListener('watcher::raf',this.funcWatcher)
        }
    }

    events()
    {
        if(this.$letters.length > 0) {
            Channels.on('watcher::raf',this.funcLetterAgency)
        }
        if(typeof this.funcWatcher !== "undefined") {
            Channels.on('watcher::raf',this.funcWatcher)
        }
    }

    lettersAgency()
    {
        const viewport = Env.framework.toolbox.getViewport()
        const scroll = Env.framework.toolbox.scrollPosition()
        let ratio = scroll.scrollY / viewport.height
        if(ratio > 1) ratio = 1

        for(let i = 0; i < this.$letters.length; i++) {
            const $letter = this.$letters[i]
            const getPosY = $letter.getAttribute('data-y') * 1
            const newPosY = ratio * getPosY
            TweenLite.set($letter , {  y : newPosY })
        }
    }

    setup()
    {
        setTimeout(()=>
        {
            this.waypoints = new FlowWaypoints(this.view)
            this.flowParallax = new FlowParallax(this.view)
            this.events()
        },1000)
        if(this.$appearReady != null)
        {
            const timing = (Env.isVisiting == true)?0:2800
            setTimeout(()=> {
                this.$appearReady.classList.add('is--shown')
            }, timing);
        }
    }
}