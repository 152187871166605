import Pixi from "pixi.js";
import Env from "../base/env";
import Channels from "../base/channels";
import TweenMax from "gsap/TweenMax";

export default class glCrewVisual {
  constructor(view) {
    if (view == null) return false;
    this.view = view;
    this.$items = this.view.querySelectorAll(".jsItem");
    this.TOTAL = this.$items.length;
    //  PIXI
    this.context = [];
    this.renderer = [];
    this.coverContainer = [];
    this.stage = [];
    this.ticker = new PIXI.ticker.Ticker();
    this.displacementSprite = [];
    this.displacementFilter = [];
    this.sprites = [];
    this.spritesReverse = [];
    this.waypointsInstances = [];
    this.FLAGS = [];
    this.setupVars();
  }

  destroy() {
    this.ticker.destroy();
    let i = 0;
    for (const e of this.waypointsInstances) {
      e.destroy();
      this.renderer[i].destroy();
      i++;
    }
  }

  install(index) {
    // Add canvas to the HTML
    this.$items[index].appendChild(this.renderer[index].view);
    // Add child container to the main container
    this.stage[index].addChild(this.coverContainer[index]);
    // Enable Interactions
    // this.stage[index].interactive = true;

    // Texture
    this.displacementSprite[index].texture.baseTexture.wrapMode =
      PIXI.WRAP_MODES.MIRRORED_REPEAT;
    this.stage[index].filters = [this.displacementFilter[index]];

    const displacementFilterVal = Env.framework.toolbox.getRandomInt(50, 300);
    this.displacementFilter[index].scale.x = displacementFilterVal;
    this.displacementFilter[index].scale.y = displacementFilterVal;

    // Coords
    this.displacementSprite[index].anchor.set(0.5);
    this.displacementSprite[index].x = 0;
    this.displacementSprite[index].y = 0;
    this.displacementSprite[index].scale.x = 2;
    this.displacementSprite[index].scale.y = 2;

    // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
    this.displacementFilter[index].autoFit = false;
    this.stage[index].addChild(this.displacementSprite[index]);
  }

  loadImage(index) {
    const $item = this.$items[index];
    // Image
    const texture = new PIXI.Texture.fromImage($item.getAttribute("data-img"));
    let image = new PIXI.Sprite(texture);
    // Dimensions
    const dimensions = this.context[index];

    // Image
    image.anchor.set(0.5);

    image.width = dimensions.width * 2;

    image.height = dimensions.height * 2;

    image.x = this.renderer[index].width / 1.98;

    image.y = this.renderer[index].height / 2;

    //

    // Record
    this.sprites[index] = image;

    // Apply
    this.coverContainer[index].addChild(image);
  }

  render() {
    this.ticker.autoStart = true;
    this.ticker.add((delta) => {
      for (let i = 0; i < this.TOTAL; i++) {
        // Render our stage
        this.renderer[i].render(this.stage[i]);
      }
    });
  }

  setupVars() {
    for (let i = 0; i < this.TOTAL; i++) {
      const $item = this.$items[i];
      const $img = $item.querySelector(".visual");
      const dimensions = $img.getBoundingClientRect();
      this.context[i] = {
        width: Math.round(dimensions.width),
        height: Math.round(dimensions.height),
      };
      this.renderer[i] = new PIXI.autoDetectRenderer(
        dimensions.width * 2,
        dimensions.height * 2,
        { transparent: true }
      );
      this.stage[i] = new PIXI.Container();
      this.coverContainer[i] = new PIXI.Container();
      this.displacementSprite[i] = new PIXI.Sprite.fromImage(
        config.pathAssets + "/img/dmaps/lake.jpg"
      );
      this.displacementFilter[i] = new PIXI.filters.DisplacementFilter(
        this.displacementSprite[i]
      );
      this.FLAGS[i] = {};
      this.FLAGS[i].wasAnimated = false;
      this.install(i);
      this.loadImage(i);

      // Randomize rendering
      let displacementSpriteVal = Env.framework.toolbox.getRandomInt(400, 1000);
      const offsetDuration = Math.round(
        Env.framework.toolbox.getRandomInt(800, 1800) / 100
      );
      const randomDuration = offsetDuration / 10;
      const typeVal = Env.framework.toolbox.getRandomInt(0, 1);
      if (typeVal == 0) displacementSpriteVal *= -1;

      const sprite = this.sprites[i];

      let timeline = new TimelineLite({ paused: true });
      sprite.alpha = 0;

      timeline.add([
        TweenLite.to(sprite, 1.5, { alpha: 1, ease: Sine.easeOut }),
        TweenLite.to(this.displacementFilter[i].scale, randomDuration, {
          x: 0,
          y: 0,
          ease: Sine.easeOut,
        }),
        TweenLite.fromTo(
          this.displacementSprite[i],
          1.5,
          { x: 0, y: 0 },
          {
            x: displacementSpriteVal,
            y: displacementSpriteVal,
            ease: Sine.easeOut,
          }
        ),
      ]);

      const instance = new Waypoint({
        element: $item,
        handler: (_direction) => {
          if (this.FLAGS[i].wasAnimated == false) {
            $item.classList.add("is--shown");
            timeline.restart();
            this.FLAGS[i].wasAnimated = true;
            instance.disable();
          }
        },
        offset: "65%",
      });
      this.waypointsInstances.push(instance);
    }
    this.render();
  }
}
