import Env from '../base/env'

export default class GlMenu
{
    constructor()
    {
        if(Env.mobile == true) return false
        this.view = Env.$mainNav
        this.viewport = Env.framework.toolbox.getViewport()
        this.renderer = new PIXI.autoDetectRenderer( this.viewport.width  , this.viewport.height  , { transparent: true })
        this.stage = new PIXI.Container()
        this.ticker = new PIXI.ticker.Ticker()
        this.imgs = {
            'dog' : {  width : 292 , height : 567 , path : config.pathAssets+'/img/menu/dog.png' },
            'rects' : { pivotX: 120, pivotY : 120, width : 1006 , height : 1099 , path : config.pathAssets+'/img/menu/blue.svg' },
            'lines' : { pivotX: 100, pivotY : 100, width : 1370 , height : 883 , path : config.pathAssets+'/img/menu/red.svg' },
            'squares' : { pivotX: 120, pivotY : 200, width : 1044 , height : 1041 , path : config.pathAssets+'/img/menu/yellow.svg' },
        }
        this.arrayImgs = [this.imgs.rects,this.imgs.lines,this.imgs.squares]
        this.CURRENT_INDEX = 0
        this.sprites = []
        this.mouseCoord = { x : 0 , y : 0 }
        this.newMouseCoord = { x : 0 , y : 0 }
        this.easing = { skew : .2 , rotation : .5 }
        this.$triggers = this.view.querySelectorAll('.jsHover--shape')


    }

    adaptRenderer()
    {
        this.renderer.view.style.maxWidth = '100%';
    }

    events()
    {
        for(let i = 0; i < this.$triggers.length; i++){
            const $trigger = this.$triggers[i]
            $trigger.addEventListener('mouseenter',()=>{
                this.showSprite(i)
            })
        }
    }

    install()
    {
        // Add canvas to the HTML
        this.view.appendChild( this.renderer.view );
        // Enable Interactions
        this.stage.interactive = true;
        // Adjust renderer
        this.adaptRenderer()

        this.stage.on('pointermove', this.pointerMove.bind(this));

        // images
        this.loadImages()

        this.render()
    }

    pointerMove(event) {
        const viewport = Env.framework.toolbox.getViewport()
        const contextWidth = viewport.width / 2
        const contextHeight = viewport.height / 2

        this.mouseCoord.x = 1 - (event.data.global.x / contextWidth)
        this.mouseCoord.y = 1 - (event.data.global.y / contextHeight)
    }

    loadImages()
    {

        const scalingOffset = 1
        // Shapes
        for(let i = 0; i<this.arrayImgs.length; i++){
            const img = this.arrayImgs[i]
            const sprite = new PIXI.Sprite.fromImage( img.path )
            const setWidth = (this.viewport.height * img.width) / img.height
            const setHeight = this.viewport.height
            sprite.anchor.set(.5)
            sprite.width = setWidth
            sprite.height = setHeight
            sprite.x = this.renderer.width / 2
            sprite.y = this.renderer.height / 2
            sprite.alpha = 0

            // Dog
            const spriteDog = new PIXI.Sprite.fromImage( this.imgs.dog.path )
            spriteDog.anchor.set(.5)
            spriteDog.width = this.imgs.dog.width
            spriteDog.height = this.imgs.dog.height
            spriteDog.x = this.renderer.width / 2
            spriteDog.y = this.renderer.height / 2
            spriteDog.alpha = 0

            // Masking
            const spriteMask = new PIXI.Sprite.fromImage( img.path )
            spriteMask.anchor.set(.5)
            spriteMask.width = ((this.viewport.height * this.imgs.squares.width) / this.imgs.squares.height)*scalingOffset
            spriteMask.height = (this.viewport.height)*scalingOffset
            spriteMask.x = this.renderer.width / 2
            spriteMask.y = this.renderer.height / 2
            spriteMask.pivot.x = img.pivotX
            spriteMask.pivot.y = img.pivotY
            spriteMask.mask = spriteDog

            this.sprites.push({canvas : sprite , dogCanvas : spriteDog , maskCanvas : spriteMask , data : img})
            this.stage.addChild(sprite)
            this.stage.addChild(spriteMask)
            this.stage.addChild(spriteDog)
        }
    }

    render()
    {
        this.ticker.autoStart = false
        this.ticker.add((delta)=>{

            this.newMouseCoord.x += (this.mouseCoord.x - this.newMouseCoord.x) / 15
            this.newMouseCoord.y += (this.mouseCoord.y - this.newMouseCoord.y) / 15

            this.sprites[this.CURRENT_INDEX].canvas.skew.x = this.newMouseCoord.x * this.easing.skew
            this.sprites[this.CURRENT_INDEX].canvas.skew.y = this.newMouseCoord.y * this.easing.skew
            this.sprites[this.CURRENT_INDEX].maskCanvas.rotation = this.newMouseCoord.x * this.easing.rotation

            // Render our stage
            this.renderer.render( this.stage );
        });
    }

    setup()
    {
        if(Env.mobile == true) return false
        this.install()
        // this.events()
    }

    startTicker()
    {
        if(typeof this.ticker != "undefined") {
            this.ticker.start()
        }
    }

    stopTicker()
    {
        if(typeof this.ticker != "undefined") {
            this.ticker.stop()
        }
    }

    showSprite(index)
    {
        for(let i = 0; i<this.sprites.length;i++) {
            const getSp = this.sprites[i].canvas
            const getDog = this.sprites[i].dogCanvas
            if(i == index) {
                this.CURRENT_INDEX = index
                TweenLite.to(getSp , .6 , { alpha : 1 , ease : Cubic.easeOut })
                TweenLite.to(getDog , .6 , { alpha : 1 , ease : Cubic.easeOut })
            } else {
                TweenLite.to(getSp , .6 , { alpha : 0 , ease : Cubic.easeOut })
                TweenLite.to(getDog , .6 , { alpha : 0 , ease : Cubic.easeOut })
            }

        }
    }
}