import Pixi from 'pixi.js'
import Env from '../base/env'
import TweenMax from 'gsap/TweenMax'
import Channels from '../base/channels'

export default class glCover
{
    constructor(view)
    {
        if(view == null) return false
        this.view = view
        this.$text = this.view.querySelector('.jsText')
        this.imageUrl = this.view.getAttribute('data-img')
        //  PIXI
        this.$refImg = this.view.querySelector('.bg')
        this.context = false
        this.viewport = Env.framework.toolbox.getViewport()
        this.viewportRate = 2
        this.renderer = new PIXI.autoDetectRenderer( this.viewport.width / this.viewportRate, this.viewport.height, { transparent: true })
        this.stage = new PIXI.Container()
        this.coverContainer = new PIXI.Container()
        this.ticker = new PIXI.ticker.Ticker()
        this.displacementSprite = new PIXI.Sprite.fromImage( config.pathAssets+'/img/dmaps/2048x2048/clouds.jpg' )
        this.displacementFilter = new PIXI.filters.DisplacementFilter( this.displacementSprite )
        this.funcResize = this.refresh.bind(this)
        this.imageSprite = false
        this.maskCover = false
        this.SPEED = 2
        this.FILTER_STRENGHT = 50
        this.install()
        this.loadImage()
        this.events()
    }

    adaptRenderer()
    {
        this.renderer.view.style.maxWidth = '100%';
        // this.renderer.view.style.top = '50%';
        // this.renderer.view.style.left = '50%';
        // this.renderer.view.style.webkitTransform = 'translate( -50%, -50% )';
        // this.renderer.view.style.transform = 'translate( -50%, -50% )';
    }

    destroy()
    {
        this.ticker.destroy()
        this.renderer.destroy()
        Channels.removeListener('window::smartresize', this.funcResize);
    }

    events()
    {
        Channels.on('window::smartresize',this.funcResize)
    }

    hoverIn()
    {
        let timeline = new TimelineLite()
        timeline.clear();

        if (timeline.isActive()) {
          return;
        }

        timeline.add([
            TweenLite.to(this.displacementFilter.scale , 2 , { x : 0 , y : 0 , ease : Power4.easeOut }),
        ])
    }

    hoverOut()
    {
        let timeline = new TimelineLite()
        timeline.clear();

        if (timeline.isActive()) {
          return;
        }

        timeline.add([
            TweenLite.to(this.displacementFilter.scale , 2 , { x : 20 , y : 20 , ease : Power4.easeOut }),
        ])
    }

    install()
    {
        // Add canvas to the HTML
        this.view.appendChild( this.renderer.view );
        // Add child container to the main container
        this.stage.addChild( this.coverContainer );
        // Enable Interactions
        this.stage.interactive = true;
        // Adjust renderer
        this.adaptRenderer()

        // Texture
        this.displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        this.stage.filters = [this.displacementFilter];

        // this.displacementFilter.scale.x = 20;
        // this.displacementFilter.scale.y = 20;


        // Coords
        this.displacementSprite.anchor.set(0.5);
        this.displacementSprite.x = this.renderer.width / 2;
        this.displacementSprite.y = this.renderer.height / 2;
        this.displacementSprite.scale.x = 2;
        this.displacementSprite.scale.y = 2;

        // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
        this.displacementFilter.autoFit = false;
        this.stage.addChild( this.displacementSprite );

        this.render()
    }

    calculateDimensionsImg()
    {
        this.context = this.$refImg.getBoundingClientRect()
        if(Env.mobile == true) {
            this.view.style.height = `${this.viewport.height}px`
        }
        if(Env.mobileCSS == true) {

            this.context = this.viewport
        }
        const realDimensions = { width : this.$refImg.getAttribute('data-width') * 1 , height : this.$refImg.getAttribute('data-height') * 1 }

        let imgSize;
        let dimensions;
        if(this.viewport.width > this.viewport.height) {
            dimensions = { height : ((this.context.width * realDimensions.height) /realDimensions.width) , width : this.context.width }
            imgSize = { width : this.context.width , height : dimensions.height }
        } else {
            console.log(this.viewport.height , this.context.height)
            dimensions = { width : ((this.context.height * realDimensions.width) /realDimensions.height) , height : this.context.height }
            imgSize = { width : dimensions.width , height : this.context.height }
        }

        this.imageSprite.anchor.set(0.5);
        this.imageSprite.width = imgSize.width
        this.imageSprite.height = imgSize.height
        this.imageSprite.x = this.renderer.width / 2;
        this.imageSprite.y = this.renderer.height / 2;

        // Mask
        this.maskCover.clear()
        const dimensionsMask = { width : ((dimensions.width - this.context.width) / 2) , height : this.viewport.height }
        const posMask = { x : (this.viewport.width - this.context.width) / 2 , y : (this.viewport.height - this.context.height) / 2 }
        this.maskCover.beginFill(0xffffff);
        this.maskCover.drawRect(posMask.x, posMask.y, this.context.width, this.context.height);

        return dimensions
    }

    loadImage()
    {
        const texture = new PIXI.Texture.fromImage(this.imageUrl)
        let image = new PIXI.Sprite(texture)
        this.imageSprite = image
        this.maskCover = new PIXI.Graphics()

        this.calculateDimensionsImg()

        // Apply
        this.stage.addChild(this.maskCover)
        this.coverContainer.mask = this.maskCover
        this.coverContainer.addChild(this.imageSprite)
    }

    refresh()
    {
        const viewport = Env.framework.toolbox.getViewport()
        this.viewport = viewport
        this.renderer.view.style.width = `${viewport.width / this.viewportRate}px`
        this.renderer.view.style.height = `${viewport.height}px`
        this.renderer.resize(this.viewport.width / this.viewportRate,this.viewport.height)
        // Image
        this.calculateDimensionsImg()
    }

    render()
    {
        this.ticker.autoStart = true
        this.ticker.add((delta)=>{
            // Optionally have a default animation
            this.displacementSprite.x += 5 * (delta / this.SPEED);
            this.displacementSprite.y += 3 * (delta / this.SPEED);

            // Filter Fx Force with scroll
            const scroll = Env.framework.toolbox.scrollPosition()
            let ratio = scroll.scrollY/(this.viewport.height * .7)
            if(ratio >= 1) ratio = 1

            // Fx
            const filterStrenght = this.FILTER_STRENGHT * ratio
            const alpha = 1 - ratio

            // Filter
            this.displacementFilter.scale.x = filterStrenght
            this.displacementFilter.scale.y = filterStrenght

            // Text
            this.$text.style.opacity = alpha

            // Render our stage
            this.renderer.render( this.stage );
        });
    }
}